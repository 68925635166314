import axios from "axios";
import authHeader from "./auth-header"

const BASE_URL =
    process.env.REACT_APP_BASE_URL +
    "api/enrolment/deferment/exemption/userListNotRegistered";

const getAll = () => {
    return axios.get(BASE_URL+"/getUserListNotRegistered", {
        headers: authHeader(),
    });
};

const save = (formData) => {
    return axios.post(BASE_URL , formData,
        {
            headers: authHeader()
        }
    );
};

const getUserAfterRegisInfo =(cid) => {
    return axios.get(
        BASE_URL + "/getUserAfterRegisInfo",
        {
            params: { cid },
            headers: authHeader(),
        }
    );
}

export default {
    getAll,
    save,
    getUserAfterRegisInfo
};