import {configureStore} from "@reduxjs/toolkit";
import topBarTitleSetterReducer from "./TopBarTitleSetter";
import authReducer from "./auth";
import messageReducer from "./message";
import sideMenuItemReducer from "./sideMenuItemSetter";

export default configureStore({
    reducer: {
        topBarTitleSetter: topBarTitleSetterReducer,
        auth: authReducer,
        msg: messageReducer,
        sideMenuItemSetter: sideMenuItemReducer,
    }
});