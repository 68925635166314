export default function authHeaderRefreshToken() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.refreshToken) {
    return {
      Authorization: "Bearer " + user.refreshToken,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
