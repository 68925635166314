import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useTranslation, i18next} from "react-i18next";

const SignupSuccess = (props) => {
    const {t, i18n} = useTranslation();
    let navigate = useNavigate();
    const [receivedData, setReceivedData] = useState(props.data);
    const [age, setAge] = useState(false);
    const [displayMessage, setDisplayMessage] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        checkMessageConditons();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setShowAlert(true);
        }, 300);
    }, []);

    function checkMessageConditons() {
        let message = "";
        if(receivedData==null || receivedData === ""){
            message = (
                <Typography align="justify" textAlign="center">
                    {t(
                        "You have successfully signed up for the Gyalsung training, now to complete the process, please login and proceed to NS Registration."
                    )}
                </Typography>
            );
        }else{
            let year = receivedData.age;
            let month = receivedData.months;

            if (month.toString().length < 2) {
                month = "0" + month;
            }
            let actualAge = year + "." + month;

            setAge(actualAge);
            if (actualAge < 16.05) {
                message = (
                    <Typography>
                        Thank you for signing up for the Gyalsung training. As you are
                        currently under 16.5 years old, you are not yet eligible to enroll in
                        the Gyalsung training. Please wait until you attain 16.5 years of age.
                        We have recorded your information and look forward to guiding you
                        through the registration steps when you become eligible at 16.5 years
                        old.
                    </Typography>
                );
            } else if (actualAge > 16.05 && actualAge < 18) {
                //and not in class 12 or studying abroad
                message = (
                    <Typography align="justify">
                        {t(
                            "Thank you for signing up for the Gyalsung training. As you are currently under 18 years old, your registration for the Gyalsung training program cannot be completed at this time. Please wait until you attain 18 years of age. However, if you are currently in class 12, you may apply for early enlistment."
                        )}
                    </Typography>
                );
            } else if (actualAge >= 18) {
                message = (
                    <Typography align="justify" textAlign="center">
                        {t(
                            "You have successfully signed up for the Gyalsung training, now to complete the process, please login and proceed to NS Registration."
                        )}
                    </Typography>
                );
            }

        }

    setDisplayMessage(message);
}

return (
    <Box gap={1} p={{xs: 2, sm: 5}} display="flex" flexDirection="column">
        <Dialog
            fullWidth={true}
            open={true}
            PaperProps={{style: {backgroundColor: "rgb(227 242 227)"}}}
        >
            <Box display={"block"}>
                <DialogContent>
                    {/* <Alert icon={false}> */}
                    <Typography className={"text-center"} variant="h4" gutterBottom>
                        <TaskAltIcon style={{fontSize: 30}}/> {t("Sign Up Success")}
                    </Typography>
                    {displayMessage}
                    <Typography>{t("Thank You")}</Typography>
                    {/* </Alert> */}
                </DialogContent>
            </Box>
            <DialogActions style={{justifyContent: "center"}}>
                <Button
                    variant="outlined"
                    endIcon={<ArrowForwardIcon/>}
                    color="primary"
                    onClick={(e) => {
                        navigate("/signin");
                    }}
                >
                    <Typography>{t("Go to Login")}</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
);
}
;

export default SignupSuccess;
