import {createSlice} from "@reduxjs/toolkit";

export const sideMenuItemSetter = createSlice({
    name: "sideMenuItemSetter",
    initialState: {
        views: []
    },
    reducers: {
        sideMenuChildrenSetter: (state, action) => {
            state.views = action.payload
        }
    }
})

export default  sideMenuItemSetter.reducer
export const {sideMenuChildrenSetter} = sideMenuItemSetter.actions