import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL =
  process.env.REACT_APP_BASE_URL +
  "api/training/management/earlyEnlistmentCutOffDate";
const saveEarlyEnlistmentCutOffDate = (data) => {
  return axios.post(BASE_URL + "/saveEarlyEnlistmentCutOffDate", data, {
    headers: authHeader(),
  });
};

const getEarlyEnlistmentCutOffDate = () => {
  return axios.get(BASE_URL + "/getEarlyEnlistmentCutOffDate", {
    headers: authHeader(),
  });
};

const updateEarlyEnlistmentCutOffDate = (data) => {
  return axios.post(BASE_URL + "/updateEarlyEnlistmentCutOffDate", data, {
    headers: authHeader(),
  });
};

const getActiveEarlyEnlistmentCutOffDate = () => {
  return axios.get(BASE_URL + "/getActiveEarlyEnlistmentCutOffDate", {
    headers: authHeader(),
  });
};

export default {
  saveEarlyEnlistmentCutOffDate,
  getEarlyEnlistmentCutOffDate,
  updateEarlyEnlistmentCutOffDate,
  getActiveEarlyEnlistmentCutOffDate,
};
