import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";
import { useTranslation, i18next } from "react-i18next";

import {
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import AuthService from "../../services/auth.services";
import WebsiteHeader from "../Home/WebsiteHeader";
import { useFormik } from "formik";
import * as Yup from "yup";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  const [urlInfo, setUrlInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [showPassword, setShowPassword] = useState({
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [alert, setAlert] = useState({
    severity: "info",
    message: "",
    show: false,
  });

  const reqErrorMsg = <span>{t("This field is required")}</span>;
  const pwLengthErrorMsg = (
    <span>{t("Password must be at least 8 characters long")}</span>
  );
  const pwPolicyErrorMsg = (
    <span>
      {t(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )}
    </span>
  );
  const confirmPwErrorMsg = <span>{t("Confirm password did match")}</span>;

  const { values, handleSubmit, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values) => resetPasswordUsingEmail(),
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .min(8, pwLengthErrorMsg)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()`*+,-./\\:;<=>?@[\]^_{|}~])[A-Za-z\d!"#$%`&'()*+,-./\\:;<=>?@[\]^_{|}~]+$/,
          pwPolicyErrorMsg
        )
        .required(reqErrorMsg),
      confirmPassword: Yup.string()
        .required(reqErrorMsg)
        .oneOf([Yup.ref("newPassword"), null], confirmPwErrorMsg),
    }),
  });

  useEffect(() => {
    validatePasswordResetLink();
  }, []);

  const validatePasswordResetLink = () => {
    setContent("");
    const requestIdFromUrl = params.get("id");
    const emailFromUrl = params.get("email");
    const data = {
      requestIdFromUrl,
      emailFromUrl,
    };

    AuthService.validatePasswordResetLink(data).then(
      (response) => {
        setUrlInfo(response.data);
      },
      (error) => {
        setContent(error.response.data.message);
        setAlert({
          severity: "warning",
          message: error.response.data.message,
          show: true,
        });
      }
    );
  };

  const resetPasswordUsingEmail = () => {
    setLoading(true);
    setAlert({ ...alert, show: false });

    const data = {
      userId: urlInfo.userId,
      email: urlInfo.email,
      password: values.newPassword,
    };

    AuthService.resetPasswordUsingEmail(data).then(
      (response) => {
        setLoading(false);
        setAlert({
          severity: "success",
          message: response.data.message,
          show: true,
        });
      },
      (error) => {
        setLoading(false);
        setAlert({
          severity: "warning",
          message: error.response.data.message,
          show: true,
        });
      }
    );
  };

  return (
    <Box bgcolor="white" position={"relative"}>
      {/*top bar*/}
      <WebsiteHeader initialY={"-10vh"} animateY={"0vh"} />
      <Box
        display="flex"
        justifyContent="center"
        height="80vh"
        alignItems="center"
        position={"relative"}
      >
        <Box
          borderRadius={2}
          boxShadow={3}
          display="flex"
          flexDirection="column"
          width={{ md: "60%", sx: "100%" }}
          maxHeight="inherit"
          sx={{
            background: "rgba(255, 255, 255, 0.77)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            "*::WebkitBackdropFilter": "blur(5px)",
            border: "1px solid rgba(255, 255, 255, 1)",
          }}
        >
          <Box
            p={{ xs: 5, sm: 5 }}
            display="flex"
            flexDirection="column"
            gap={2}
          >
            {content ? (
              <>
                <Alert severity="warning" variant="outlined">
                  <Typography align="justify">{content}</Typography>
                </Alert>
                <Stack
                  spacing={2}
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="center"
                >
                  <Button
                    startIcon={<ChevronLeftIcon />}
                    variant="outlined"
                    color="info"
                    style={{ textTransform: "initial" }}
                    onClick={(e) => {
                      navigate("/signin");
                    }}
                  >
                    {t("Go to Login")}
                  </Button>
                  <Button
                    endIcon={<ChevronRightIcon />}
                    color="info"
                    variant="outlined"
                    style={{ textTransform: "initial" }}
                    onClick={(e) => {
                      navigate("/forgotPassword");
                    }}
                  >
                    {t("Reset Password")}
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                <Stack spacing={2} justifyContent="center" alignItems="center">
                  <Typography color="text.disabled" align="center" variant="h5">
                    {t("Reset Password")}
                  </Typography>
                </Stack>
                {alert.severity === "success" ? (
                  <Collapse in={alert.show}>
                    <Alert
                      variant="outlined"
                      severity={alert.severity}
                      action={
                        <IconButton
                          onClick={() => {
                            setAlert({ ...alert, show: false });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      <Typography align="justify">
                        {t(
                          "You have successfully changed your password. Please login using your new password"
                        )}{" "}
                        <Link to="/signIn">{t("Click here to login")}</Link>
                      </Typography>
                    </Alert>
                  </Collapse>
                ) : (
                  <>
                    <Stack
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <TextField
                        inputProps={{ maxLength: 30, autoComplete: "off" }}
                        type={
                          showPassword.showNewPassword ? undefined : "password"
                        }
                        required
                        label={t("New Password")}
                        placeholder={t("New Password")}
                        size="small"
                        disabled={loading}
                        value={values.newPassword}
                        onChange={(e) =>
                          setFieldValue("newPassword", e.target.value)
                        }
                        helperText={touched.newPassword && errors.newPassword}
                        error={errors.newPassword && touched.newPassword}
                        sx={{ width: { xs: "100%", md: "50%" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              <IconButton
                                onClick={() =>
                                  setShowPassword({
                                    ...showPassword,
                                    showNewPassword:
                                      !showPassword.showNewPassword,
                                  })
                                }
                              >
                                {showPassword.showNewPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        inputProps={{ maxLength: 30, autoComplete: "off" }}
                        type={
                          showPassword.showConfirmPassword
                            ? undefined
                            : "password"
                        }
                        required
                        label={t("Confirm Password")}
                        placeholder={t("Confirm Password")}
                        size="small"
                        disabled={loading}
                        value={values.confirmPassword}
                        onChange={(e) =>
                          setFieldValue("confirmPassword", e.target.value)
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        error={
                          errors.confirmPassword && touched.confirmPassword
                        }
                        sx={{ width: { xs: "100%", md: "50%" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              <IconButton
                                onClick={() =>
                                  setShowPassword({
                                    ...showPassword,
                                    showConfirmPassword:
                                      !showPassword.showConfirmPassword,
                                  })
                                }
                              >
                                {showPassword.showConfirmPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        startIcon={<ChevronLeftIcon />}
                        variant="outlined"
                        disabled={loading}
                        color="info"
                        style={{ textTransform: "initial" }}
                        onClick={(e) => {
                          navigate("/signin");
                        }}
                      >
                        <Typography>{t("Go to Login")}</Typography>
                      </Button>
                      <Button
                        style={{ textTransform: "initial" }}
                        disabled={loading}
                        type="submit"
                        variant="outlined"
                        color="info"
                        onClick={() => handleSubmit()}
                      >
                        <Typography>{t("Reset Now")}</Typography>
                        {loading && (
                          <CircularProgress
                            sx={{
                              position: "absolute",
                              p: 1,
                            }}
                          />
                        )}
                      </Button>
                    </Stack>
                  </>
                )}

                <Collapse in={alert.show && alert.severity !== "success"}>
                  <Alert
                    severity={alert.severity}
                    variant="outlined"
                    action={
                      <IconButton
                        onClick={() => {
                          setAlert({ ...alert, show: false });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <Typography align="justify">{alert.message}</Typography>
                  </Alert>
                </Collapse>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
