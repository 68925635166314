import {LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REGISTER_FAIL, REGISTER_SUCCESS,} from "../actions/types";
import {createSlice} from "@reduxjs/toolkit";

//localStorage.removeItem("user");
// console.log(Object.entries(localStorage.getItem("user")))
const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? {isLoggedIn: true, user}
    : {isLoggedIn: false, user: null};

export const authenticationSlice = createSlice({
    name: "Authentication",
    initialState: initialState,
    reducers: {
        auth: (state = initialState, action) => {
            const {type, payload} = action;
            switch (type) {
                case REGISTER_SUCCESS:
                    return {
                        ...state,
                        isLoggedIn: false,
                    };
                case REGISTER_FAIL:
                    return {
                        ...state,
                        isLoggedIn: false,
                    };
                case LOGIN_SUCCESS:
                    return {
                        ...state,
                        isLoggedIn: true,
                        user: payload.user,
                    };
                case LOGIN_FAIL:
                    return {
                        ...state,
                        isLoggedIn: false,
                        user: null,
                    };
                case LOGOUT:
                    return {
                        ...state,
                        isLoggedIn: false,
                        user: null,
                    };
                default:
                    return state;
            }
        }
    }
});

export default authenticationSlice.reducer;
export const {auth} = authenticationSlice.actions;
