import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL + "api/user/profile/signup";

class RegisterService {
  getAgeCriteria() {
    return axios.get(BASE_URL + "/getAgeCriteria");
  }

  getCitizenDetail(cid, dob) {
    return axios.get(BASE_URL + "/getCitizenDetails", {
      // return axios.get("api/user/profile/signup" + "/getCitizenDetails", {
      params: {
        cid,
        dob,
      },
    });
  }

  validateCitizenDetails(cid, dob) {
    return axios.get(BASE_URL + "/validateCitizenDetails", {
      params: {
        cid,
        dob,
      },
    });
  }

  receiveOtp(data) {
    return axios.post(BASE_URL + "/receiveOtp", data);
    // return axios.post("api/user/profile/signup" + "/receiveOtp", data);
  }
  verifyOtp(data) {
    return axios.post(BASE_URL + "/verifyOtp", data);
    // return axios.post("api/user/profile/signup" + "/verifyOtp", data);
  }

  receiveEmailVcode(data) {
    return axios.post(BASE_URL + "/receiveEmailVcode", data);
    // return axios.post("api/user/profile/signup" + "/receiveEmailVcode", data);
  }

  verifyEmailVcode(data) {
    return axios.post(BASE_URL + "/verifyEmailVcode", data);
    // return axios.post("api/user/profile/signup" + "/verifyEmailVcode", data);
  }
}

export default new RegisterService();
