import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL =
  process.env.REACT_APP_BASE_URL +
  "api/training/management/nsRegistrationCutOffDate";
const saveNsRegistrationCutOffDate = (data) => {
  return axios.post(BASE_URL + "/saveNsRegistrationCutOffDate", data, {
    headers: authHeader(),
  });
};

const getNsRegistrationCutOffDate = () => {
  return axios.get(BASE_URL + "/getNsRegistrationCutOffDate", {
    headers: authHeader(),
  });
};

const updateNsRegistrationCutOffDate = (data) => {
  return axios.post(BASE_URL + "/updateNsRegistrationCutOffDate", data, {
    headers: authHeader(),
  });
};

const getActiveNsRegistrationCutOffDate = () => {
  return axios.get(BASE_URL + "/getActiveNsRegistrationCutOffDate", {
    headers: authHeader(),
  });
};

export default {
  saveNsRegistrationCutOffDate,
  getNsRegistrationCutOffDate,
  updateNsRegistrationCutOffDate,
  getActiveNsRegistrationCutOffDate,
};
