import React, {useState} from "react";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import {Alert, Collapse, Stack, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";

const EarlyEnlistmentInstruction = ({registrationEndDate,enlistmentCurYear}) => {
    const [open, setOpen] = useState(true);

    return (
        <PageTransitionFadeIn
            child={
                <Stack sx={{ width: "100%" }} spacing={1}>
                    {enlistmentCurYear!=='2024'&&<Collapse in={open}>
                        <Alert
                            variant="outlined"
                            severity="info"
                            icon={false}
                            sx={{
                                mb: 2,
                                width: "100%",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            <Typography
                                gutterBottom
                                variant="h6"
                                fontWeight="bold"
                            >
                                <InfoOutlinedIcon /> The registration for 2025 intake is now open and the last date is {registrationEndDate}. Please register before deadline.
                            </Typography>

                        </Alert>
                    </Collapse>}
                    <Collapse in={open}>
                        <Alert
                            variant="outlined"
                            severity="info"
                            icon={false}
                            sx={{
                                mb: 2,
                                width: "100%",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            <Typography
                                gutterBottom
                                variant="h6"
                                fontWeight="bold"
                            >
                                <CampaignOutlinedIcon /> Early enlistment is only for those who are below 18 and above 16.6 years, and completed class 12 in the previous year or earlier.
                                If you have not completed class 12, or if you are illterate, please do not apply.
                            </Typography>

                        </Alert>
                    </Collapse>
                </Stack>
            }
        />
    );
};

export default EarlyEnlistmentInstruction;
