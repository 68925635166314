import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL =
    process.env.REACT_APP_BASE_URL + "api/training/management/reasons";
const save = (formData) => {
  return axios.post(
      BASE_URL,
      formData,
      {
        headers: authHeader(),
      }
  );
};

const getAll = () => {
  return axios.get(
      BASE_URL,
      { headers: authHeader() }
  );
};

const getAllById = (id) => {
  return axios.get(
      BASE_URL + "/getAllReasonById",
      { params: { id }, headers: authHeader() }
  );
};

const update = (formData) => {
  return axios.put(
      BASE_URL + "/updateReason",
      formData,
      {
        headers: authHeader(),
      }
  );
};

const getAllByStatus = (status) => {
  return axios.get(
      BASE_URL + "/getAllReasonByStatus",
      { params: { status }, headers: authHeader() }
  );
};

const getReasonForDefermentExemption = (defermentExemption,status) => {
  return axios.get(
      BASE_URL + "/getAllReasonByExemptDeferment",
      { params: { defermentExemption,status }, headers: authHeader() }
  );
};

export default {
  save,
  getAll,
  getAllByStatus,
  getAllById,
  update,
  getReasonForDefermentExemption

};
