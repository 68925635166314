import {
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
  DialogContent,
  DialogTitle,
  DialogActions,
  Dialog,
  Divider,
} from "@mui/material";
import "../../App.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import registerService from "../../services/register.service";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import countryList from "react-select-country-list";
import OtpInput from "react-otp-input";
import Alert from "@mui/material/Alert";
import CustomTooltip from "./CustomTooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import NDIlogobg from "../../assets/ndi/NDIlogobg.png";
import NdiServices from "../../services/ndi.service";
import SignUpNDIScanCode from "../../NDI/SignUpNDIScanCode";
import { useTranslation, i18next } from "react-i18next";

const SignUpContactDetail = ({
  personalData,
  contactDetail,
  setContactDetail,
  setDisableNextBtn,
  contactDetailFormik,
  helperFormik,
}) => {
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [verificationStatus, setVerificationStatus] = useState({
    smsCodeSent: false,
    emailCodeSent: false,
  });

  const isMobileView = useMediaQuery("(max-width: 600px)");

  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showHelperDialog, setShowHelperDialog] = useState(false);
  const [myselfChecked, setMyselfChecked] = useState(false);
  const [someoneChecked, setSomeoneChecked] = useState(false);

  const otpTime = process.env.REACT_APP_OTP_TIME_OUT;
  const [otpTimeout, setOtpTimeout] = useState(otpTime);
  const [showOtpDialog, setShowOtpDialog] = useState(false);

  const [vcodeTimeout, setVcodeTimeout] = useState(otpTime);
  const [showVcodeDialog, setShowVcodeDialog] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSendOtpProgress, setShowSendOtpProgress] = useState(false);

  const [showSendEmailVcodeProgress, setShowSendEmailVcodeProgress] =
    useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [helperEntered, setHelperEntered] = useState(false);
  const [ndiSignupScanOpen, setNdiSignupOpen] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {}, []);

  useEffect(() => {
    if (otpTimeout > 0) {
      const interval = setInterval(() => {
        setOtpTimeout((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpTimeout]);

  useEffect(() => {
    if (vcodeTimeout > 0) {
      const interval = setInterval(() => {
        setVcodeTimeout((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [vcodeTimeout]);

  useEffect(() => {
    if (!helperEntered) {
      setShowHelperDialog(true);
    }
  }, [helperEntered]);

  useEffect(() => {
    verifyOtp();
  }, [contactDetailFormik.values.otp]);

  useEffect(() => {
    verifyEmailVcode();
  }, [contactDetailFormik.values.verificationCode]);

  useEffect(() => {
    setDisableNextBtn(false);

    if (helperFormik.values.helperRequired !== null) {
      setShowHelperDialog(false);
    }

    // width change
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (verificationStatus.smsCodeSent && verificationStatus.emailCodeSent) {
      setDisableNextBtn(false);
    }
  }, [verificationStatus]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleRemoveHelper = () => {
    helperFormik.setFieldValue("helperRequired", "N");
  };

  const handleEditHelper = () => {
    helperFormik.setFieldValue("helperRequired", "Y");
    setShowHelperDialog(true);
    setMyselfChecked(false);
    setSomeoneChecked(true);
  };

  const handlePhoneChange = (value, country) => {
    contactDetailFormik.setFieldValue("mobileNo", value);
    contactDetailFormik.setFieldValue("countryCode", country.dialCode);
  };

  const sendOtp = () => {
    receiveOtp();
  };

  const receiveOtp = async () => {
    contactDetailFormik.setFieldValue("otp", "");
    setOtpTimeout(otpTime);
    setShowSendOtpProgress(true);
    setShowSnackbar(false);
    const mobileNo = contactDetailFormik.values.mobileNo;
    const data = { mobileNo };
    await registerService.receiveOtp(data).then(
      (response) => {
        setVerificationStatus({ ...verificationStatus, smsCodeSent: true });
        setSnackbarMessage(`OTP sent to ${mobileNo}`);
        setShowSnackbar(true);
        setShowOtpDialog(true);
        setOtpTimeout(otpTime);
      },
      (error) => {
        setSnackbarMessage(error.response.data.message);
        setShowSnackbar(true);
      }
    );
    setShowSendOtpProgress(false);
  };

  const receiveEmailVcode = async () => {
    contactDetailFormik.setFieldValue("verificationCode", "");
    setVcodeTimeout(otpTime);
    setShowSendEmailVcodeProgress(true);
    setShowSnackbar(false);
    const email = contactDetailFormik.values.email;
    const data = { email };
    registerService.receiveEmailVcode(data).then(
      (response) => {
        setVerificationStatus({ ...verificationStatus, emailCodeSent: true });
        setSnackbarMessage(`Verification code sent to ${email}`);
        setShowSnackbar(true);
        setShowVcodeDialog(true);
        setVcodeTimeout(otpTime);
      },
      (error) => {
        setSnackbarMessage(error.response.data.message);
        setShowSnackbar(true);
      }
    );
  };

  const verifyOtp = () => {
    const mobileNo = contactDetailFormik.values.mobileNo;
    const otp = contactDetailFormik.values.otp;
    const data = { mobileNo, otp };
    if (otp.length === 6) {
      registerService.verifyOtp(data).then(
        (response) => {
          setShowOtpDialog(false);
          setShowSendOtpProgress(false);
          setSnackbarMessage("Your mobile number has been verified.");
          setShowSnackbar(true);
          contactDetailFormik.setFieldValue("mobileNoVerified", true);
        },
        (error) => {
          setSnackbarMessage(error.response.data.message);
          setShowSnackbar(true);
        }
      );
    }
  };

  const verifyEmailVcode = () => {
    const email = contactDetailFormik.values.email;
    const vcode = contactDetailFormik.values.verificationCode;
    const data = { email, verificationCode: vcode };
    if (vcode.length === 6) {
      registerService.verifyEmailVcode(data).then(
        (response) => {
          setShowVcodeDialog(false);
          setShowSendEmailVcodeProgress(false);
          setSnackbarMessage("Your email has been verified.");
          setShowSnackbar(true);
          contactDetailFormik.setFieldValue("emailVerified", true);
        },
        (error) => {
          setSnackbarMessage(error.response.data.message);
          setShowSnackbar(true);
        }
      );
    }
  };

  const proofNdiRequest = (helper) => {
    NdiServices.proofNdiRequestSignUp(helper).then((res) => {
      setUrl(res.data.inviteURL);
      natsListener(res.data.threadId);
    });
  };
  //
  async function natsListener(threadId) {
    let endPoint =
      BASE_URL + "api/user/profile/ndi/subscribe?threadId=" + threadId;
    let eventSource = new EventSource(endPoint);
    eventSource.addEventListener("NDI_SSI_EVENT", (event) => {
      setNdiSignupOpen(false);
      let data = JSON.parse(event.data);
    });
  }

  const handleOkay = () => {
    if (helperFormik.values.helperRequired === "Y") {
      helperFormik.handleSubmit();
      const shouldCloseDialog =
        helperFormik.values.helperName !== "" &&
        helperFormik.values.helperMobileNo !== "" &&
        helperFormik.values.helperEmail !== "" &&
        helperFormik.values.helperRelation !== "";

      if (shouldCloseDialog) {
        setShowHelperDialog(false);
      }
      setHelperEntered(true);
    } else {
      setShowHelperDialog(false);
      setHelperEntered(true);
    }
  };

  const handleConfirmPasswordChange = async (e) => {
    contactDetailFormik.setFieldValue("confirmPassword", e.target.value);
  };

  const handlePasswordChange = (e) => {
    contactDetailFormik.setFieldValue("password", e.target.value);
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        action={snackbarAction}
      />
      {/* signup helper detail dialog */}
      <Dialog
        open={showHelperDialog}
        PaperProps={{ style: { backgroundColor: "#f3f3f3" } }}
      >
        <Box display={"block"}>
          <DialogTitle>
            {/* <Typography textAlign="center">Who is signing up?</Typography> */}
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {helperFormik.values.helperRequired === "N" ||
              helperFormik.values.helperRequired === null ? (
                <Typography textAlign="center" variant="h5">
                  {t("Hi")} {personalData.fullName},{" "}
                  {t("Are you signing up yourself, or is someone helping you")}?
                </Typography>
              ) : (
                <></>
              )}
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="N"
                    checked={myselfChecked}
                    control={<Radio />}
                    label={t("I am signing up myself")}
                    onClick={(e) => {
                      setMyselfChecked(true);
                      setSomeoneChecked(false);
                      helperFormik.setFieldValue(
                        "helperRequired",
                        e.target.value
                      );
                    }}
                  />
                  <FormControlLabel
                    value="Y"
                    checked={someoneChecked}
                    control={<Radio />}
                    label={t("Someone is helping")}
                    onClick={(e) => {
                      setMyselfChecked(false);
                      setSomeoneChecked(true);
                      helperFormik.setFieldValue(
                        "helperRequired",
                        e.target.value
                      );
                    }}
                  />
                </RadioGroup>
              </FormControl>

              <Box
                sx={{
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "center",
                  display:
                    helperFormik.values.helperRequired === "Y"
                      ? "flex"
                      : "none",
                }}
              >
                <Alert icon={false} severity="info" variant="outlined">
                  <Typography>
                    {t(
                      "Thank you for helping {{fullName}} sign up for the Gyalsung National Service. We believe that {{fullName}} might be illeterate or doesn't have a mobile or email. Please fill out your details. Gyalsung HQ would like to acknowledge your assistance.",
                      {
                        fullName: personalData.fullName,
                      }
                    )}
                  </Typography>
                </Alert>

                <TextField
                  required
                  fullWidth
                  placeholder={t("Enter your name")}
                  label={t("Enter your name")}
                  size="small"
                  inputProps={{ maxLength: 30, autoComplete: "off" }}
                  value={helperFormik.values.helperName}
                  onChange={(e) =>
                    helperFormik.setFieldValue("helperName", e.target.value)
                  }
                  error={
                    helperFormik.touched.helperName &&
                    helperFormik.errors.helperName
                  }
                  helperText={"Required" && helperFormik.errors.helperName}
                />

                <TextField
                  fullWidth
                  disabled={showSendOtpProgress}
                  type="number"
                  required
                  placeholder={t("Enter your mobile number")}
                  label={t("Enter your mobile number")}
                  size="small"
                  inputProps={{ maxLength: 15, autoComplete: "off" }}
                  value={helperFormik.values.helperMobileNo}
                  onChange={(e) =>
                    helperFormik.setFieldValue("helperMobileNo", e.target.value)
                  }
                  error={
                    helperFormik.touched.helperMobileNo &&
                    helperFormik.errors.helperMobileNo
                  }
                  helperText={"Required" && helperFormik.errors.helperMobileNo}
                />
                <TextField
                  fullWidth
                  disabled={showSendOtpProgress}
                  type="email"
                  placeholder={t("Enter your email")}
                  label={t("Enter your email")}
                  size="small"
                  inputProps={{ maxLength: 50, autoComplete: "off" }}
                  value={helperFormik.values.helperEmail}
                  onChange={(e) =>
                    helperFormik.setFieldValue("helperEmail", e.target.value)
                  }
                  error={
                    helperFormik.touched.helperEmail &&
                    helperFormik.errors.helperEmail
                  }
                  helperText={"Required" && helperFormik.errors.helperEmail}
                />

                <TextField
                  required
                  select
                  fullWidth
                  label={t("Relation")}
                  size="small"
                  value={helperFormik.values.helperRelation}
                  onChange={(e) =>
                    helperFormik.setFieldValue("helperRelation", e.target.value)
                  }
                  error={
                    helperFormik.touched.helperRelation &&
                    helperFormik.errors.helperRelation
                  }
                  helperText={"Required" && helperFormik.errors.helperRelation}
                >
                  <MenuItem value="Government Official">
                    Government Official
                  </MenuItem>
                  <MenuItem value="Friend">Friend</MenuItem>
                  <MenuItem value="Relative">Relative</MenuItem>
                </TextField>
              </Box>
            </Box>
          </DialogContent>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          {helperFormik.values.helperRequired !== null ? (
            <Button
              variant="outlined"
              color="success"
              style={{ textTransform: "initial" }}
              onClick={() => {
                handleOkay();
              }}
            >
              <Typography>{t("Okay")}</Typography>
            </Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>

      {/*otp dialog*/}
      <Dialog
        open={showOtpDialog}
        PaperProps={{ style: { backgroundColor: "#f3f3f3" } }}
      >
        <Box display={"block"}>
          <DialogTitle>
            <Typography textAlign="center">
              {t("Please enter OTP in")}{" "}
              <span style={{ color: "red" }}>{formatTime(otpTimeout)}</span>{" "}
              {t("MMSS")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {otpTimeout > 0 ? (
                <OtpInput
                  value={contactDetailFormik.values.otp}
                  onChange={(code) => {
                    contactDetailFormik.setFieldValue("otp", code);
                  }}
                  numInputs={6}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: isMobileView ? screenWidth * 0.1 : "54px",
                    height: isMobileView ? screenWidth * 0.1 : "54px",
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "400",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                  shouldAutoFocus={true}
                />
              ) : (
                <>
                  <Alert severity="warning" variant="outlined">
                    <Typography align="justify">
                      {t(
                        "Time lasped. Please request a new OTP. Click Resend if the email is the same."
                      )}
                    </Typography>
                  </Alert>
                </>
              )}

              <Typography textAlign="center">
                {(() => {
                  const translatedString = t("OTP code is sent to").replace(
                    "%mobileNumber%",
                    contactDetailFormik.values.mobileNo
                  );
                  return translatedString;
                })()}{" "}
                {t("Didn't receive")}?
                <Button
                  variant="text"
                  style={{ textTransform: "initial" }}
                  onClick={receiveOtp}
                >
                  <Typography style={{ color: "#0088d2" }}>
                    {t("Resend")}
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </DialogContent>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "initial" }}
            variant="outlined"
            color="warning"
            onClick={() => {
              setShowOtpDialog(false);
              setShowSendOtpProgress(false);
            }}
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/*email verification code dialog*/}
      <Dialog
        open={showVcodeDialog}
        PaperProps={{ style: { backgroundColor: "#f3f3f3" } }}
      >
        <Box display={"block"}>
          <DialogTitle>
            <Typography textAlign="center">
              {t("Please enter OTP in")}{" "}
              <span style={{ color: "red" }}>{formatTime(otpTimeout)}</span>{" "}
              {t("MMSS")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {vcodeTimeout > 0 ? (
                <OtpInput
                  value={contactDetailFormik.values.verificationCode}
                  onChange={(code) => {
                    contactDetailFormik.setFieldValue("verificationCode", code);
                  }}
                  numInputs={6}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: isMobileView ? screenWidth * 0.1 : "54px",
                    height: isMobileView ? screenWidth * 0.1 : "54px",
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "400",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                  shouldAutoFocus={true}
                />
              ) : (
                <>
                  <Alert severity="warning" variant="outlined">
                    <Typography align="justify">
                      {t(
                        "Time lasped. Please request a new OTP. Click Resend if the email is the same."
                      )}
                    </Typography>
                  </Alert>
                </>
              )}

              <Typography textAlign="center">
                {(() => {
                  const translatedString = t("OTP code is sent to").replace(
                    "%mobileNumber%",
                    contactDetailFormik.values.email
                  );
                  return translatedString;
                })()}{" "}
                {t("Didn't receive")}?
                <Button
                  variant="text"
                  style={{ textTransform: "initial" }}
                  onClick={receiveEmailVcode}
                >
                  <Typography style={{ color: "#0088d2" }}>
                    {t("Resend")}
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </DialogContent>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "initial" }}
            variant="outlined"
            color="warning"
            onClick={() => {
              setShowVcodeDialog(false);
              setShowSendEmailVcodeProgress(false);
            }}
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/*Password policy dialog*/}
      <Dialog
        open={showPasswordDialog}
        PaperProps={{ style: { backgroundColor: "#f3f3f3" } }}
      >
        <Box display={"block"}>
          <DialogTitle>
            <Typography textAlign="center" variant="h5">
              {t("What makes strong password")}?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography textAlign="center">
                {t("Your password must fulfill the following conditions")}:
              </Typography>
              <ul>
                <li>
                  {t(
                    "It should be at least 8 characters long but less than 30 characters."
                  )}
                </li>
                <li>
                  {t(
                    "It should be a mix of letters, numbers and special characters."
                  )}
                </li>
                <li>
                  {t(
                    "It should contain at least one special character, a number, a block letter, or a small letter."
                  )}
                </li>
              </ul>
            </Box>
          </DialogContent>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "initial" }}
            variant="outlined"
            color="success"
            onClick={() => setShowPasswordDialog(false)}
          >
            <Typography>{t("Okay")}</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      {helperFormik.values.helperRequired === "Y" ? (
        <>
          <Stack
            spacing={2}
            color="gray"
            padding={1}
            sx={{
              // border: "1px solid #0088d1",
              border: "1px solid #c5c5c5",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Typography>
              {/* Dear {helperFormik.values.helperName},<br />
              Thank you for assisting {personalData.fullName} complete the
              signup. An OTP and verification code will be sent to your mobile
              and email. Please use that to continue the sign-up process. */}
              {t(
                "Thank you for assisting {{fullName}} complete the signup. An OTP and verification code will be sent to your mobile and email. Please use that to continue the sign-up process.",
                {
                  fullName: personalData.fullName,
                }
              )}
            </Typography>
            <Divider />
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography noWrap>
                {t("Name")}: <strong>{helperFormik.values.helperName}</strong>
              </Typography>
              <Typography noWrap>
                {t("Mobile Number")}:
                <strong>{helperFormik.values.helperMobileNo}</strong>
              </Typography>
              <Typography noWrap>
                {t("Email")}: <strong>{helperFormik.values.helperEmail}</strong>
              </Typography>
              <Typography noWrap>
                {t("Relalation")}:
                <strong>{helperFormik.values.helperRelation}</strong>
              </Typography>
              <Typography noWrap>
                <Button
                  variant="text"
                  color="error"
                  style={{ textTransform: "initial" }}
                  onClick={handleRemoveHelper}
                >
                  <DeleteIcon sx={{ fontSize: "13px" }} />{" "}
                  <Typography>{t("Remove")}</Typography>
                </Button>
                <Button
                  variant="text"
                  color="info"
                  style={{ textTransform: "initial" }}
                  onClick={handleEditHelper}
                >
                  <EditIcon sx={{ fontSize: "13px" }} />{" "}
                  <Typography>{t("Edit")}</Typography>
                </Button>
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          color="gray"
          padding={1}
          sx={{ border: "1px solid #c5c5c5", justifyContent: "flex-end" }}
        >
          <Typography onClick={handleEditHelper} style={{ cursor: "pointer" }}>
            {t(
              "If you are not signing up yourself, please click here to add the assistor's details"
            )}
            {/* If you are not signing up yourself, please
           <Button
              variant="text"
              color="info"
              style={{ textTransform: "initial" }}
              onClick={handleEditHelper}
            >
              click here
            </Button>{" "}
            to add the assistor's details. */}
          </Typography>
        </Stack>
      )}

      {/*Mobile number & email*/}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        marginBottom={1}
      >
        {/*Present country*/}
        <Box flex={1}>
          <FormControl fullWidth size="small">
            <InputLabel>{t("Present Country")}</InputLabel>
            <Select
              required
              value={contactDetailFormik.values.presentCountry}
              label="Country Country"
              onChange={(e) => {
                contactDetailFormik.setFieldValue(
                  "presentCountry",
                  e.target.value
                );
              }}
            >
              {Object.keys(countryList().getLabelList()).map((countryName) => {
                const capitalizedCountry =
                  countryName.charAt(0).toUpperCase() + countryName.slice(1);
                return (
                  <MenuItem
                    key={`country_${capitalizedCountry}`}
                    value={capitalizedCountry}
                  >
                    {capitalizedCountry}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <CustomTooltip text={t("Country where you currently live")}>
            <Typography variant="body2">
              <InfoIcon color="info" />
            </Typography>
          </CustomTooltip>
        </Box>

        <Box flex={1} sx={{ border: "1px solid #c5c5c5" }} p={2}>
          <Stack spacing={2} marginBottom={1}>
            <Typography>{t("How do you want to verify")}?</Typography>
            <RadioGroup
              size
              aria-label="anonymous"
              // onChange={(e) => {
              //   setPhoneOrEmailVerification(e.target.value);
              // }}

              onChange={(e) => {
                contactDetailFormik.setFieldValue(
                  "phoneOrEmailVerification",
                  e.target.value
                );
              }}
              name="anonymous"
              value={contactDetailFormik.values.phoneOrEmailVerification}
              row
              sx={{ justifyItems: "center" }}
            >
              <FormControlLabel
                value="phone"
                control={<Radio />}
                label={t("Phone")}
                disabled={
                  contactDetailFormik.values.mobileNoVerified ||
                  contactDetailFormik.values.emailVerified
                }
              />
              <FormControlLabel
                value="email"
                control={<Radio />}
                label={t("Email")}
                disabled={
                  contactDetailFormik.values.mobileNoVerified ||
                  contactDetailFormik.values.emailVerified
                }
              />
            </RadioGroup>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            marginBottom={1}
          >
            {/*mobile number*/}
            {contactDetailFormik.values.phoneOrEmailVerification ===
              "phone" && (
              <>
                <Box flex={1}>
                  <div className="phone-input-container">
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      enableSearch
                      country={"bt"}
                      value={contactDetailFormik.values.mobileNo}
                      onChange={handlePhoneChange}
                      disabled={
                        showSendOtpProgress ||
                        contactDetailFormik.values.mobileNoVerified
                      }
                    />
                    <label
                      htmlFor="phone-input"
                      className={`floating-label ${
                        contactDetailFormik.values.mobileNo ? "filled" : ""
                      }`}
                    >
                      {t("Mobile Number")}*
                    </label>
                  </div>
                  <CustomTooltip
                    text={t(
                      "Only Bhutanese mobile numbers will receive the OTP."
                    )}
                  >
                    <Typography variant="body2">
                      <InfoIcon color="info" />
                    </Typography>
                  </CustomTooltip>
                </Box>

                {/*Mobile number OPT field will show only if country code is Bhutan i.e 975, to be changed later*/}
                {contactDetailFormik.values.countryCode === "975" ? (
                  <Box
                    display="flex"
                    flex={0.3}
                    alignItems="center"
                    sx={{
                      display:
                        contactDetailFormik.values.mobileNo.length > 3
                          ? !contactDetailFormik.values.mobileNoVerified
                            ? "block"
                            : "none"
                          : "none",
                    }}
                  >
                    <Button
                      fullWidth
                      variant="outlined"
                      color="info"
                      onClick={() => sendOtp()}
                      disabled={showSendOtpProgress}
                    >
                      <Box
                        sx={{ display: "flex", gap: 1 }}
                        style={{ textTransform: "initial" }}
                      >
                        <Typography noWrap>{t("Send OTP")}</Typography>
                        <CircularProgress
                          sx={{
                            display: showSendOtpProgress ? "block" : "none",
                          }}
                          size={"1rem"}
                        />
                        <SendIcon />
                      </Box>
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            )}
            {/* Email */}
            {contactDetailFormik.values.phoneOrEmailVerification ===
              "email" && (
              <>
                <Box flex={1}>
                  <TextField
                    inputProps={{ maxLength: 100, autoComplete: "off" }}
                    fullWidth
                    disabled={
                      showSendEmailVcodeProgress ||
                      contactDetailFormik.values.emailVerified
                    }
                    type="email"
                    placeholder={t("Email")}
                    label={t("Email")}
                    size="small"
                    required
                    value={contactDetailFormik.values.email}
                    onChange={(e) => {
                      contactDetailFormik.setFieldValue(
                        "email",
                        e.target.value
                      );
                    }}
                    error={
                      contactDetailFormik.touched.email &&
                      contactDetailFormik.errors.email
                    }
                    helperText={
                      contactDetailFormik.touched.email &&
                      contactDetailFormik.errors.email
                    }
                  />
                </Box>
                {/* send verification code */}
                <Box
                  display="flex"
                  flex={0.5}
                  alignItems="center"
                  sx={{
                    display:
                      contactDetailFormik.values.email.length > 2
                        ? !contactDetailFormik.values.emailVerified
                          ? "block"
                          : "none"
                        : "none",
                  }}
                >
                  <Button
                    style={{ textTransform: "initial" }}
                    fullWidth
                    variant="outlined"
                    color="info"
                    disabled={showSendEmailVcodeProgress}
                    onClick={async () => {
                      const emailValidationRegExp =
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                          contactDetailFormik.values.email
                        );

                      if (emailValidationRegExp) {
                        contactDetailFormik.setTouched({ email: true });
                      } else {
                        receiveEmailVcode();
                      }
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: 1 }}
                      style={{ textTransform: "initial" }}
                    >
                      <Typography noWrap>{t("Send OTP")}</Typography>
                      <CircularProgress
                        sx={{
                          display: showSendEmailVcodeProgress
                            ? "block"
                            : "none",
                        }}
                        size="1rem"
                      />
                      <SendIcon />
                    </Box>
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      </Stack>

      {(contactDetailFormik.values.mobileNoVerified ||
        contactDetailFormik.values.emailVerified) && (
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Box flex={1}>
            <TextField
              inputProps={{ maxLength: 30, autoComplete: "off" }}
              type={showPassword ? "text" : "password"}
              value={contactDetailFormik.values.password}
              fullWidth
              placeholder={t("Password")}
              label={t("Password")}
              size="small"
              required
              onChange={handlePasswordChange}
              error={
                contactDetailFormik.touched.password &&
                contactDetailFormik.errors.password
              }
              helperText={
                contactDetailFormik.touched.password &&
                contactDetailFormik.errors.password
              }
              onFocus={() =>
                contactDetailFormik.setFieldTouched("password", true, false)
              }
              onBlur={() =>
                contactDetailFormik.setFieldTouched("password", true, true)
              }
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
            <Typography
              variant="body2"
              display="block"
              style={{ textAlign: "left", cursor: "pointer" }}
              onClick={() => setShowPasswordDialog(true)}
            >
              <InfoIcon color="info" /> {t("What makes strong password")}?
            </Typography>
          </Box>

          <Box flex={1}>
            <TextField
              inputProps={{ maxLength: 30, autoComplete: "off" }}
              type={showConfirmPassword ? "text" : "password"}
              value={contactDetailFormik.values.confirmPassword}
              fullWidth
              placeholder={t("Confirm Password")}
              label={t("Confirm Password")}
              size="small"
              required
              onChange={handleConfirmPasswordChange}
              error={
                contactDetailFormik.touched.confirmPassword &&
                contactDetailFormik.errors.confirmPassword
              }
              helperText={
                contactDetailFormik.touched.confirmPassword &&
                contactDetailFormik.errors.confirmPassword
              }
              onFocus={() =>
                contactDetailFormik.setFieldTouched(
                  "confirmPassword",
                  true,
                  false
                )
              }
              onBlur={() =>
                contactDetailFormik.setFieldTouched(
                  "confirmPassword",
                  true,
                  true
                )
              }
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default SignUpContactDetail;
