import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import store from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pathGenerator } from "../../helper/PathGenerator";
import { logout } from "../../actions/auth";
import profileService from "../../services/profile.service";
import { motion } from "framer-motion";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from "@mui/icons-material/Menu";
import Fade from "@mui/material/Fade";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import AuthService from "../../services/auth.services";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BallotIcon from "@mui/icons-material/Ballot";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import GroupIcon from "@mui/icons-material/Group";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import QuizIcon from "@mui/icons-material/Quiz";
import LockResetIcon from "@mui/icons-material/LockReset";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import StartIcon from "@mui/icons-material/Start";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import SubjectIcon from "@mui/icons-material/Subject";
import Collapse from "@mui/material/Collapse";
import RemoveIcon from "@mui/icons-material/Remove";
import logo from "../../assets/logo/logo-gyalsung.png";

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "none",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));

const TopBar = (props) => {
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { user: currentUser } = useSelector((state) => state.auth);
  const siginUser = JSON.parse(localStorage.getItem("user"));

  const [openMenu, setOpenMenu] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showTopBarTitle, setShowTopBarTitle] = useState(false);
  const [topBarTitle, setTopBarTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayName, setDisplayName] = useState("");

  const [allowedPages, setAllowedPages] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [selectedMenuGroup, setSelectedMenuGroup] = useState("");
  const [isCollapsed, setIsCollapsed] = useState({});

  useEffect(() => {
    getProfileInfo();
    // ---------------------------------
    const u = JSON.parse(localStorage.getItem("user"));
    let tmpSideMenuItem = u.accessScreens;
    const data = {};
    let fData = [];
    const tmpIsCollapse = {};
    const tL = [];

    for (const i in tmpSideMenuItem) {
      // if it is part of menu item group
      if (
        ![
          "gyalsup profile",
          "edit mobile number",
          "edit parent info",
          "enlistment date setup",
          "edit email address",
          "edit password",
          "edit social media link",
          "edit census record",
          "edit current user",
          "edit user name",
          "edit guardian info",
        ].includes(tmpSideMenuItem[i]["screen_name"].toLowerCase())
      ) {
        if (Object.keys(tmpSideMenuItem[i]).includes("screen_group_name")) {
          // check if screen group name already exist
          if (
            Object.keys(data).includes(tmpSideMenuItem[i]["screen_group_name"])
          ) {
            if (!tL.includes(tmpSideMenuItem[i]["screen_name"])) {
              data[tmpSideMenuItem[i]["screen_group_name"]] = [
                ...data[tmpSideMenuItem[i]["screen_group_name"]],
                tmpSideMenuItem[i]["screen_name"],
              ];
              tL.push(tmpSideMenuItem[i]["screen_name"]);
            }
          } else {
            data[tmpSideMenuItem[i]["screen_group_name"]] = [
              tmpSideMenuItem[i]["screen_name"],
            ];
          }
        } else {
          data[tmpSideMenuItem[i]["screen_name"]] = [
            tmpSideMenuItem[i]["screen_name"],
          ];
        }
      }
    }
    for (const key in data) {
      // sort element under group
      data[key] = data[key].sort();
      tmpIsCollapse[key] = false;
    }
    // const cleanedMenuData = [];
    if (Object.keys(data).length > 0) {
      Object.keys(data)
        .sort()
        .reduce((obj, key) => {
          if (key === "Dashboard") {
            fData.splice(0, 0, { [key]: data[key] });
          } else fData.push({ [key]: data[key] });
          return null;
        });
    }
    // when group has only one view allowed in the group and rest are not viewable
    for (const i in fData) {
      if (fData[i][Object.keys(fData[i])[0]].length === 1) {
        if (
          fData[i][Object.keys(fData[i])[0]][0] !== Object.keys(fData[i])[0]
        ) {
          fData = Object.assign([], fData, {
            [i]: {
              [fData[i][Object.keys(fData[i])[0]]]:
                fData[i][Object.keys(fData[i])[0]],
            },
          });
        }
      }
    }

    setIsCollapsed(tmpIsCollapse);
    setAllowedPages(fData);
    setSelectedMenuGroup("Dashboard");
    setSelectedMenuItem("Dashboard");
    // ---------------------------------
  }, []);

  useEffect(() => {
    setShowTopBarTitle(false);

    setTimeout(() => {
      setTopBarTitle(store.getState().topBarTitleSetter.title);
      setShowTopBarTitle(true);
    }, 300);
  }, [store.getState().topBarTitleSetter.title]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();

  const handleLogOut = useCallback(() => {
    AuthService.logout().then(
      (response) => {
        localStorage.clear();
        navigate("/");
      },
      (error) => {
        localStorage.clear();
        navigate("/");
      }
    );
  }, [dispatch]);

  const [mobileOpen, setMobileOpen] = useState(false);

  useSelector((state) => state.topBarTitleSetter);

  const navigate = useNavigate();

  const iconsToMenuItem = {
    Course: <SubjectIcon style={{ color: "black" }} />,
    "Master Data": <BedroomParentIcon style={{ color: "black" }} />,
    Medical: <MonitorHeartIcon style={{ color: "black" }} />,
    "Gyalsung Registration Date Setup": (
      <PunchClockIcon style={{ color: "black" }} />
    ),
    Dashboard: <DashboardIcon style={{ color: "black" }} />, // dashboard
    "Parent Consent": <ArticleIcon style={{ color: "black" }} />, // Submit parent consent
    "Submit Parent Consent": <ArticleIcon style={{ color: "black" }} />, // Submit parent consent
    "Parent Consent List": <ListAltIcon style={{ color: "black" }} />, // parent consent list
    "Enrollment Config": <FollowTheSignsIcon style={{ color: "black" }} />, // enrollment
    Enrollment: <FollowTheSignsIcon style={{ color: "black" }} />, // enrollment
    "Enrollment List": <BallotIcon style={{ color: "black" }} />, // enrollment list
    "Apply Deferment": <StartIcon style={{ color: "black" }} />, // apply deferment
    Deferment: <LowPriorityIcon style={{ color: "black" }} />, // deferment list
    "Deferment List": <LowPriorityIcon style={{ color: "black" }} />, // deferment list
    "Apply Exemption": <DisabledByDefaultIcon style={{ color: "black" }} />, // apply exemption
    Exemption: <ContentPasteOffIcon style={{ color: "black" }} />, // exemption list
    "Exemption List": <ContentPasteOffIcon style={{ color: "black" }} />, // exemption list
    "Medical Booking": <EnhancedEncryptionIcon style={{ color: "black" }} />, // medical booking
    "Edit Medical Booking": <BloodtypeIcon style={{ color: "black" }} />, // edit medical booking
    "Appointment List": <ClearAllIcon style={{ color: "black" }} />, // appointment list
    "Training Academy": <HolidayVillageIcon style={{ color: "black" }} />, // training academy
    "Academy Wise Enrollment": <GroupAddIcon style={{ color: "black" }} />, // academy wise enrollment
    Users: <GroupIcon style={{ color: "black" }} />, // users
    "Role Wise Access Permission": (
      <VisibilityOffIcon style={{ color: "black" }} />
    ), // RoleWiseAccessPermission
    "Send Notifications": <EditNotificationsIcon style={{ color: "black" }} />, // send notifications
    "Enlistment Date Setup": <CalendarMonthIcon style={{ color: "black" }} />, // enlistment date setup
    "Field Specialization": <SmartButtonIcon style={{ color: "black" }} />, // field specialization
    "Medical Category Setup": <LocalPharmacyIcon style={{ color: "black" }} />, // medical category setup
    "Medical Questionnaire setup": <QuizIcon style={{ color: "black" }} />, // medical questionnaire setup
    "Reset User Password": <LockResetIcon style={{ color: "black" }} />, // reset user password
    "Training Academy Intake": <AddBusinessIcon style={{ color: "black" }} />, // training academy intake
    "Notice Configuration": <AddAlertIcon style={{ color: "black" }} />, // notice configuration
    "Dzongkhag Hospital Mapping": (
      <AccountTreeIcon style={{ color: "black" }} />
    ), //dzongkhag hospital mapping
    "Hospital Schedule Time Setup": <MoreTimeIcon style={{ color: "black" }} />, // hospital schedule time setup
    "Hospital Schedule Time List": <ScheduleIcon style={{ color: "black" }} />, // hospital schedule time list
    Reason: <CardMembershipIcon style={{ color: "black" }} />, // reason
    "Registration Date Setup": (
      <FormatIndentIncreaseIcon style={{ color: "black" }} />
    ), // registration date setup
    "Screen Group Setup": (
      <FormatIndentIncreaseIcon style={{ color: "black" }} />
    ), // Screen Group setup
    "Screen Setup": <FormatIndentIncreaseIcon style={{ color: "black" }} />, // Screen  setup
  };

  const drawerText = [
    "Sign Out",
    "Mode",
    "Dashboard",
    "Submit Parent Consent",
    "Enrollment",
    "Enrollment List",
    "Apply Deferment",
    "Deferment List",
    "Apply Exemption",
    "Exemption List",
    "Medical Booking",
    "Edit Medical Booking",
    "Training Academy",
    "Academy Wise Enrollment",
    "Master Data",
    "Enlist Date Setup",
    "Course",
    "Medical Category Setup",
    "Medical Questionnaire Setup",
    "Reset user Password",
    "Training Academy Intake",
    "Notice Configuration",
    "Dzongkhag Hospital Mapping",
    "Hospital Schedule Time",
    "Hospital Schedule Time List",
    "Reason",
    "Role Setup",
  ];

  const drawer = (
    <Box>
      <Toolbar
        sx={{
          background: "linear-gradient(to right, rgb(4 4 4), rgb(4 4 4))",
        }}
      >
        {/* <Typography variant="h3">Gyalsung s</Typography> */}
        <img
          src={logo}
          alt="logo"
          width={"70%"}
          // height={"60%"}
          // style={{ maxWidth: "100%", paddingBottom: "75%" }}
        />
      </Toolbar>
      <Divider />
      <List
        sx={{
          height: "100vh",
          overflow: "auto",
        }}
        dense
      >
        {allowedPages.map((item, index) => {
          return Object.values(item)[0].length === 1 ? (
            <ListItemButton
              key={Object.keys(item)[0]}
              sx={{
                backgroundColor:
                  selectedMenuItem === Object.keys(item)[0]
                    ? theme.palette.mode === "dark"
                      ? "#868dfb !important"
                      : colors.main[900]
                    : null,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "#868dfb !important"
                      : colors.main[900],
                },
              }}
              onClick={() => {
                setSelectedMenuGroup(Object.keys(item)[[0]]);
                setSelectedMenuItem(Object.keys(item)[0]);
                navigate(pathGenerator(Object.keys(item)[0]));
                handleDrawerToggle();
              }}
            >
              <ListItemIcon>
                {iconsToMenuItem[Object.keys(item)[0]]}
              </ListItemIcon>
              <ListItemText>
                <Typography color={"black"}>{Object.keys(item)[0]}</Typography>
              </ListItemText>
            </ListItemButton>
          ) : (
            <Box key={Object.keys(item)[0]}>
              <ListItemButton
                onClick={() => {
                  setSelectedMenuGroup(Object.keys(item)[0]);
                  setSelectedMenuItem(Object.keys(item)[0]);
                  setIsCollapsed({
                    ...isCollapsed,
                    [Object.keys(item)[0]]: !isCollapsed[Object.keys(item)[0]],
                  });
                }}
                sx={{
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "#868dfb !important"
                        : colors.main[900],
                  },
                  backgroundColor:
                    selectedMenuGroup === Object.keys(item)[0]
                      ? theme.palette.mode === "dark"
                        ? "#868dfb !important"
                        : colors.main[900]
                      : null,
                }}
              >
                <ListItemIcon>
                  {iconsToMenuItem[Object.keys(item)[0]]}
                </ListItemIcon>
                <ListItemText>
                  <Typography>{Object.keys(item)[0]}</Typography>
                </ListItemText>
                {isCollapsed[Object.keys(item)[0]] ? (
                  <ExpandLess />
                ) : (
                  <ExpandLess />
                )}
              </ListItemButton>
              {/* sub menu items */}
              <Collapse sx={{ pl: 3 }} in={isCollapsed[Object.keys(item)[0]]}>
                {Object.values(item)[0].map((child, index) => {
                  return (
                    <ListItemButton
                      key={child}
                      sx={{
                        "&:hover": {
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#868dfb !important"
                              : colors.main[900],
                        },
                        backgroundColor:
                          selectedMenuItem === child
                            ? theme.palette.mode === "dark"
                              ? "#868dfb !important"
                              : colors.main[900]
                            : null,
                      }}
                      onClick={() => {
                        setSelectedMenuItem(child);
                        navigate(pathGenerator(child));
                        handleDrawerToggle();
                      }}
                    >
                      {/* <ListItemIcon>{iconsToMenuItem[child]}</ListItemIcon> */}
                      <ListItemIcon>
                        <RemoveIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography>{child}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  );
                })}
              </Collapse>
            </Box>
          );
        })}
      </List>
      <Divider />
    </Box>
  );

  const drawerWidth = 240;

  const getProfileInfo = () => {
    if (siginUser !== null) {
      profileService.getProfileInfo(siginUser.userId).then(
        (response) => {
          setDisplayName(response.data.fullName);
        },
        (error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
      );
    }
  };

  return (
    <Box>
      <motion.div
        initial={{ y: "-10vh" }}
        animate={{ y: "0vh" }}
        transition={{ duration: 0.8 }}
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 3,
        }}
      >
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            background: colors.main[900],
          }}
        >
          <Toolbar sx={{ display: "flex" }}>
            {/* drawer button*/}
            <ListIcon
              sx={{ display: { xs: "block", sm: "none" } }}
              onClick={handleDrawerToggle}
            />
            {/*Top Bar Title*/}
            <Fade in={showTopBarTitle}>
              <Typography
                noWrap
                px={3}
                color="white" //{theme.palette.mode==="dark"?"black":"black"}
                variant={"h3"}
              >
                {" ⌊ " + topBarTitle + " ⏋"}
              </Typography>
            </Fade>
            {/*logout button and fullname*/}
            <Stack
              direction="row"
              flex={1}
              justifyContent="right"
              justifyItems="right"
            >
              <Icons>
                <Typography>{displayName}</Typography>

                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleLogOut}
                >
                  Logout <LogoutIcon />
                </Typography>
              </Icons>
              <UserBox>
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleLogOut}
                >
                  Logout <LogoutIcon />
                </Typography>
              </UserBox>
            </Stack>
          </Toolbar>
        </AppBar>
        {/*drawer for mobile*/}
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            // shows when screen is extra-small
            display: { xs: "block", sm: "none" },
            // scroll bar style
            "*::-webkit-scrollbar": {
              width: "3px",
            },
            "*::-webkit-scrollbar-track": {
              background: "#E4EFEF",
            },
            "*::-webkit-scrollbar-thumb": {
              background:
                theme.palette.mode === "dark" ? "#1D388F61" : "#fbd388",
            },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {/* mobile phone*/}
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? colors.primary[400]
                    : undefined,
              },
            }}
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </motion.div>
    </Box>
  );
};

export default TopBar;
