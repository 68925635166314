import { Box, useTheme } from "@mui/material";
import TopBar from "./TopBar/TopBar";
import SideMenu from "./SideMenu/SideMenu";
import Toolbar from "@mui/material/Toolbar";
import RoutePaths from "../routes";
import * as React from "react";
import { tokens } from "../theme";
import useAuth from "../CustomHooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import OpenUserDashboard from "./OpenUserDashboard/OpenUserDashboard";
import SideMenuStudent from "./SideMenu/SideMenuStudent";
import TopBarStudent from "./TopBar/TopBarStudent";

const AuthenticatedViewWrapper = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const drawerWidth = 240;
  const isAuthenticated = useAuth();
  const navigate = useNavigate();
  const [userType, setUserType] = useState(false);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      const userData = JSON.parse(localStorage.getItem("user"));
      const userType = userData.roles.filter((item) => {
        return item.userType === "S";
      });
      if (userType.length > 0) setUserType(true);
    }
  }, []);

  return (
    <>
      {!userType && (
        <Box
          display="flex"
          bgcolor={
            theme.palette.mode !== "dark" ? colors.primary[400] : undefined
          }
          flexDirection={"row"}
        >
          <TopBar />
          <SideMenu />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              height: "100%",
              backgroundColor:
                theme.palette.mode !== "dark" ? colors.primary[400] : undefined,
            }}
          >
            <Toolbar />
            <RoutePaths />
          </Box>
        </Box>
      )}
      {userType && (
        <Box
          display="flex"
          bgcolor={
            theme.palette.mode !== "dark" ? colors.primary[400] : undefined
          }
          flexDirection={"row"}
        >
          <TopBarStudent />
          <SideMenuStudent />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              height: "100%",
              backgroundColor:
                theme.palette.mode !== "dark" ? colors.primary[400] : undefined,
            }}
          >
            <Toolbar />
            <RoutePaths />
          </Box>
        </Box>
      )}
    </>
  );
};

export default AuthenticatedViewWrapper;
