import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import {
    Box, CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormControlLabel, IconButton,
    InputLabel, MenuItem,
    Select,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import WebsiteHeader from "../Home/WebsiteHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import TextField from "@mui/material/TextField";
import {motion} from "framer-motion";
import NDIlogobg from "../../assets/ndi/NDIlogobg.png";
import Alert from "@mui/material/Alert";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import countryList from "react-select-country-list";
import CustomTooltip from "./CustomTooltip";
import InfoIcon from "@mui/icons-material/Info";
import Fade from "@mui/material/Fade";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QRCode from "react-qr-code";
import PhoneInput from "react-phone-input-2";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";

const NDISignUp = () => {
    const isMobileView = useMediaQuery("(max-width:600px)");
    const BG_COLOR = "#edf7ed";

    return (
        <PageTransitionFadeIn
            child={
                <Box maxHeight={"auto"}>
                    {/*pass true, inside website header it will be inverted*/}
                    <WebsiteHeader
                        initialY={"-10vh"}
                        animateY={"0vh"}
                        showSignUp={true}
                    />
                    {isMobileView ? <Box height={15}/> : <Toolbar/>}
                    <Container>
                        <Box
                            sx={{
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    width: "100%",
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    height={"auto"}
                                    gap={1}
                                    ml={5}
                                    mr={5}
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Toolbar/>
                                        <motion.div
                                            initial={{scale: 0}}
                                            animate={{scale: 1}}
                                            transition={{delay: 0.3, duration: 0.8, type: "spring"}}
                                            style={{
                                                maxHeight: "inherit",
                                                // width: mobileScreen ? "1200%" : "30%",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <Box
                                                flex="100%"
                                                boxShadow={3}
                                                // display="flex"
                                                flexDirection="column"
                                                // alignItems="center"
                                                // height="auto"
                                                // width= "100%"
                                                //mx={mobileScreen ? 1 : 0}
                                                maxHeight="inherit"
                                                sx={{
                                                    //alignItems: "center",
                                                    display: "flex",
                                                    height: "fit-content",
                                                    background: "white",
                                                    borderRadius: "4px",
                                                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                                                    backdropFilter: "blur(5px)",
                                                    "*::WebkitBackdropFilter": "blur(5px)",
                                                    border: "1px solid rgba(255, 255, 255, 1)",
                                                    padding: "16px"
                                                }}
                                            >
                                                <br/>
                                                {/*info cards*/}
                                                <Typography
                                                    variant="h5"
                                                    style={{display: "block"}}
                                                >
                                                    <Alert severity="info">
                                                        These personnel and family information is provided by NDI
                                                        (National Digital Identity Bhutan)
                                                    </Alert>
                                                </Typography>
                                                <br/>
                                                <Stack
                                                    direction={{xs: "column", sm: "row"}}
                                                    spacing={3}
                                                    style={{display: "flex"}}
                                                >

                                                    <Box
                                                        flex={1}
                                                        boxShadow={3}
                                                        pt={3}
                                                        borderRadius={2}
                                                        sx={{
                                                            background: "#e0e0e0",
                                                        }}
                                                    >
                                                        <ul>Name: Thinley Dema</ul>
                                                        <ul>Gender: Female</ul>
                                                        <ul>CID: 11111111</ul>
                                                        <ul>Date of Birth: 01/01/1998</ul>
                                                    </Box>
                                                    {/*card 2*/}
                                                    <Box
                                                        bgcolor={BG_COLOR}
                                                        flex={1}
                                                        boxShadow={3}
                                                        pt={3}
                                                        borderRadius={2}
                                                        sx={{
                                                            background: "#e0e0e0",
                                                        }}
                                                    >
                                                        <ul>Father's name: Sonam Wangchuk</ul>
                                                        <ul>Father's CID: 222222222</ul>
                                                        <ul>Mother's name: Pema Lhamo</ul>
                                                        <ul>Mother's CID: 333333333</ul>
                                                    </Box>
                                                    {/*card 3*/}
                                                    <Box
                                                        bgcolor={BG_COLOR}
                                                        flex={1}
                                                        boxShadow={3}
                                                        pt={3}
                                                        borderRadius={2}
                                                        sx={{
                                                            background: "#e0e0e0",
                                                        }}
                                                    >
                                                        <ul>Village: Bunakha</ul>
                                                        <ul>Gewog: Chapcha</ul>
                                                        <ul>Dzongkhag: Chukha</ul>
                                                    </Box>
                                                </Stack>
                                                <br/>
                                                <Stack
                                                    direction={{xs: "column", sm: "row"}}
                                                    spacing={2}
                                                    color="gray"
                                                    padding={1}
                                                    sx={{border: "1px solid #c5c5c5", justifyContent: "flex-start"}}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 2,
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                            >
                                                                <FormControlLabel
                                                                    value="N"
                                                                    checked={true}
                                                                    control={<Radio/>}
                                                                    label="I am signing up myself"
                                                                />
                                                                <FormControlLabel
                                                                    value="Y"
                                                                    checked={false}
                                                                    control={<Radio/>}
                                                                    label="Someone is helping"
                                                                />
                                                                <FormControlLabel
                                                                    value="Y"
                                                                    checked={false}
                                                                    control={<Radio/>}
                                                                    label="Someone is helping (Register Using NDI)"
                                                                />
                                                                {/*<Box*/}
                                                                {/*    className="col-01 mt-2" // You can add additional custom class names here*/}
                                                                {/*    sx={{*/}
                                                                {/*        backgroundColor: `#124143`,*/}
                                                                {/*        borderRadius: "20px",*/}
                                                                {/*        mt: 3*/}
                                                                {/*    }}*/}
                                                                {/*>*/}
                                                                {/*    <a*/}
                                                                {/*        href="/loginBhutanNDI" // You can replace this with the actual URL*/}
                                                                {/*        className="btn col-md-12 custom-btn"*/}
                                                                {/*    >*/}
                                                                {/*        <Typography sx={{ color: '#F0F9F4' }}><img*/}
                                                                {/*            src={NDIlogobg}*/}
                                                                {/*            width="30"*/}
                                                                {/*            height="30"*/}
                                                                {/*            alt="NDI Logo"*/}
                                                                {/*            style={{ marginRight: '10px' }}*/}
                                                                {/*        />*/}
                                                                {/*            Sign up with Bhutan NDI</Typography>*/}
                                                                {/*    </a>*/}
                                                                {/*</Box>*/}
                                                            </RadioGroup>

                                                        </FormControl>
                                                    </Box>

                                                </Stack>
                                                <br/>
                                                {/*<Stack*/}
                                                {/*    direction={{ xs: "column", sm: "column" }}*/}
                                                {/*    spacing={2}*/}
                                                {/*    color="gray"*/}
                                                {/*    padding={1}*/}
                                                {/*    sx={{ border: "1px solid #c5c5c5", justifyContent: "flex-start" }}*/}
                                                {/*>*/}
                                                {/*    <Box*/}
                                                {/*        sx={{*/}
                                                {/*            flexDirection: "row",*/}
                                                {/*            gap: 2,*/}
                                                {/*            alignItems: "center",*/}
                                                {/*            display: "flex",*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*    <Typography>*/}
                                                {/*        Dear Tshering Penjor,<br />*/}
                                                {/*        Thank you for assisting Thinley Dema complete the*/}
                                                {/*        signup. An OTP and verification code will be sent to your mobile*/}
                                                {/*        and email. Please use that to continue the sign-up process.*/}
                                                {/*    </Typography>*/}
                                                {/*    </Box>*/}
                                                {/*    <Box*/}
                                                {/*        sx={{*/}
                                                {/*            flexDirection: "row",*/}
                                                {/*            gap: 2,*/}
                                                {/*            alignItems: "center",*/}
                                                {/*            display: "flex",*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {/*        <Typography>CID: 11111111</Typography>*/}
                                                {/*        <Typography>Full Name: Tshering Penjor</Typography>*/}
                                                {/*    </Box>*/}
                                                {/*    <Box*/}
                                                {/*        sx={{*/}
                                                {/*            flexDirection: "row",*/}
                                                {/*            gap: 2,*/}
                                                {/*            alignItems: "center",*/}
                                                {/*            display: "flex",*/}
                                                {/*        }}*/}
                                                {/*    >*/}

                                                {/*        <TextField*/}
                                                {/*            fullWidth*/}
                                                {/*            type="number"*/}
                                                {/*            placeholder="Enter your mobile no*"*/}
                                                {/*            label="Mobile no*"*/}
                                                {/*            size="small"*/}
                                                {/*            inputProps={{ maxLength: 15, autoComplete: "off" }}*/}
                                                {/*        />*/}
                                                {/*        <TextField*/}
                                                {/*            fullWidth*/}
                                                {/*            type="email"*/}
                                                {/*            placeholder="Email*"*/}
                                                {/*            label="Email*"*/}
                                                {/*            size="small"*/}
                                                {/*            inputProps={{ maxLength: 50, autoComplete: "off" }}*/}
                                                {/*        />*/}
                                                {/*        <TextField*/}
                                                {/*            required*/}
                                                {/*            select*/}
                                                {/*            fullWidth*/}
                                                {/*            label="Relation"*/}
                                                {/*            size="small"*/}
                                                {/*        >*/}
                                                {/*            <MenuItem value="Government Official">*/}
                                                {/*                Government Official*/}
                                                {/*            </MenuItem>*/}
                                                {/*            <MenuItem value="Friend">Friend</MenuItem>*/}
                                                {/*            <MenuItem value="Relative">Relative</MenuItem>*/}
                                                {/*        </TextField>*/}
                                                {/*    </Box>*/}
                                                {/*</Stack>*/}
                                                {/*<br/>*/}
                                                {/*Mobile number & email*/}
                                                <Stack
                                                    direction={{xs: "column", sm: "row"}}
                                                    spacing={2}
                                                    marginBottom={1}
                                                >
                                                    {/*Present country*/}
                                                    <Box flex={1}>
                                                        <FormControl fullWidth size="small">
                                                            <InputLabel>Present Country</InputLabel>
                                                            <Select
                                                                required
                                                                //value={contactDetailFormik.values.presentCountry}
                                                                label="Country Country"
                                                                // onChange={(e) => {
                                                                //     contactDetailFormik.setFieldValue(
                                                                //         "presentCountry",
                                                                //         e.target.value
                                                                //     );
                                                                // }}
                                                            >
                                                                {Object.keys(countryList().getLabelList()).map((countryName) => {
                                                                    const capitalizedCountry =
                                                                        countryName.charAt(0).toUpperCase() + countryName.slice(1);
                                                                    return (
                                                                        <MenuItem
                                                                            key={`country_${capitalizedCountry}`}
                                                                            value={capitalizedCountry}
                                                                        >
                                                                            {capitalizedCountry}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                        <CustomTooltip text="Country where you currently live">
                                                            <Typography variant="body2">
                                                                <InfoIcon color="info"/>
                                                            </Typography>
                                                        </CustomTooltip>
                                                    </Box>
                                                    <Box flex={1} sx={{border: "1px solid #c5c5c5"}} p={2}>
                                                        <Stack spacing={2} marginBottom={1}>
                                                            <Typography>How do you want to verify?</Typography>
                                                            <RadioGroup
                                                                size
                                                                aria-label="anonymous"
                                                                // onChange={(e) => {
                                                                //   setPhoneOrEmailVerification(e.target.value);
                                                                // }}

                                                                // onChange={(e) => {
                                                                //     contactDetailFormik.setFieldValue(
                                                                //         "phoneOrEmailVerification",
                                                                //         e.target.value
                                                                //     );
                                                                // }}
                                                                name="anonymous"
                                                                //value={contactDetailFormik.values.phoneOrEmailVerification}
                                                                row
                                                                sx={{justifyItems: "center"}}
                                                            >
                                                                <FormControlLabel
                                                                    value="phone"
                                                                    checked={false}
                                                                    control={<Radio/>}
                                                                    label="Phone"
                                                                    // disabled={
                                                                    //     contactDetailFormik.values.mobileNoVerified ||
                                                                    //     contactDetailFormik.values.emailVerified
                                                                    // }
                                                                />
                                                                <FormControlLabel
                                                                    value="email"
                                                                    control={<Radio/>}
                                                                    checked={true}

                                                                    label="Email"
                                                                    // disabled={
                                                                    //     contactDetailFormik.values.mobileNoVerified ||
                                                                    //     contactDetailFormik.values.emailVerified
                                                                    // }
                                                                />
                                                            </RadioGroup>
                                                        </Stack>
                                                        <Stack
                                                            direction={{xs: "column", sm: "row"}}
                                                            spacing={2}
                                                            marginBottom={1}
                                                        >
                                                            <Box flex={1}>
                                                                <TextField
                                                                    inputProps={{ maxLength: 100, autoComplete: "off" }}
                                                                    fullWidth
                                                                    type="email"
                                                                    placeholder="Email"
                                                                    label="Email"
                                                                    size="small"
                                                                    required

                                                                />
                                                                {/*<div className="phone-input-container">*/}
                                                                {/*    <PhoneInput*/}
                                                                {/*        inputStyle={{width: "100%"}}*/}
                                                                {/*        enableSearch*/}
                                                                {/*        country={"bt"}*/}

                                                                {/*    />*/}
                                                                {/*    <label*/}
                                                                {/*        htmlFor="phone-input"*/}
                                                                {/*        className={`floating-label  "filled"`}*/}
                                                                {/*    >*/}
                                                                {/*        Mobile Number**/}
                                                                {/*    </label>*/}

                                                                {/*</div>*/}
                                                                <CustomTooltip
                                                                    text="Only Bhutanese mobile numbers will receive the OTP.">
                                                                    <Typography variant="body2">
                                                                        <InfoIcon color="info"/>
                                                                    </Typography>
                                                                </CustomTooltip>

                                                            </Box>

                                                            {/*<Box*/}
                                                            {/*    display="flex"*/}
                                                            {/*    flex={0.3}*/}
                                                            {/*    alignItems="center"*/}
                                                            {/*    sx={{*/}
                                                            {/*        display: "block"*/}
                                                            {/*    }}*/}
                                                            {/*>*/}
                                                            {/*    <Button*/}
                                                            {/*        fullWidth*/}
                                                            {/*        variant="contained"*/}
                                                            {/*        color="info"*/}
                                                            {/*    >*/}
                                                            {/*        <Box*/}
                                                            {/*            sx={{ display: "flex", gap: 1 }}*/}
                                                            {/*            style={{ textTransform: "initial" }}*/}
                                                            {/*        >*/}
                                                            {/*            <Typography noWrap>Send Vefiricaition Code</Typography>*/}
                                                            {/*            <SendIcon />*/}
                                                            {/*        </Box>*/}
                                                            {/*    </Button>*/}
                                                            {/*</Box>*/}
                                                        </Stack>
                                                    </Box>
                                                </Stack>
                                                <Stack direction={{xs: "column", sm: "row"}} spacing={2}>

                                                    <Box flex={1}>
                                                        <TextField
                                                            inputProps={{maxLength: 30, autoComplete: "off"}}
                                                            type={"password"}
                                                            fullWidth
                                                            placeholder="Password"
                                                            label="Password"
                                                            size="small"
                                                            required
                                                        />
                                                    </Box>
                                                    <Box flex={1}>
                                                        <TextField
                                                            inputProps={{maxLength: 30, autoComplete: "off"}}
                                                            type={"password"}
                                                            fullWidth
                                                            placeholder="Confirm Password"
                                                            label="Confirm Password"
                                                            size="small"
                                                            required
                                                        />
                                                    </Box>


                                                </Stack>
                                                <br/>
                                                <Stack>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        gap={1}
                                                        justifyContent="right"
                                                        // my={3}
                                                    >
                                                        <Button
                                                            //onClick={handleBack}
                                                            variant="outlined"
                                                            style={{textTransform: "initial"}}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </motion.div>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            }
        />
    );
};

export default NDISignUp;
