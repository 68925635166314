import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Alert from "@mui/lab/Alert";
import OtpInput from "react-otp-input";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InputAdornment from "@mui/material/InputAdornment";
import authServices from "../../services/auth.services";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation, i18next } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomTooltip from "../SignUp/CustomTooltip";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";

import {
  Divider,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  Box,
  useMediaQuery,
  FormControlLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import WebsiteHeader from "../Home/WebsiteHeader";
import WebsiteFooter from "../Home/WebsiteFooter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ForgotPassword = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });
  const [showSendOtpProgress, setShowSendOtpProgress] = useState(false);
  const [snackbar, setSnackbar] = useState({
    severity: "info",
    message: "",
    show: false,
  });
  const [enteredMobileNumber, setEnteredMobileNumber] = useState("");
  const [mobileNoVerified, setMobileNoVerified] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const otpTime = process.env.REACT_APP_OTP_TIME_OUT;
  const [otpTimeout, setOtpTimeout] = useState(otpTime);
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const [otp, setOtp] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showPassword, setShowPassword] = useState({
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const reqLabel = <span>{t("This field is required")}</span>;
  const pwLengthErrorMsg = (
    <span>{t("Password must be at least 8 characters long")}</span>
  );
  const pwPolicyErrorMsg = (
    <span>
      {t(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )}
    </span>
  );
  const confirmPwErrorMsg = <span>{t("Confirm password did match")}</span>;

  const {
    values: values1,
    handleSubmit: handleSubmit1,
    setFieldValue: setFieldValue1,
    touched: touched1,
    errors: errors1,
  } = useFormik({
    initialValues: {
      cid: "",
      dob: "",
      cidAndDobVerified: false,
      resetPasswordMedium: null,
    },
    onSubmit: (values) => verifyCidAndDob(values),
    validationSchema: Yup.object().shape({
      cid: Yup.string().required(reqLabel),
      dob: Yup.string().required(reqLabel),
    }),
  });

  const {
    values: values2,
    handleSubmit: handleSubmit2,
    setFieldValue: setFieldValue2,
    touched: touched2,
    errors: errors2,
  } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values) => resetPasswordUsingMobileNo(),
    //todo: password validation according to password policy
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .min(8, pwLengthErrorMsg)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()`*+,-./\\:;<=>?@[\]^_{|}~])[A-Za-z\d!"#$%`&'()*+,-./\\:;<=>?@[\]^_{|}~]+$/,
          pwPolicyErrorMsg
        )
        .required(reqLabel),
      confirmPassword: Yup.string()
        .required(reqLabel)
        .oneOf([Yup.ref("newPassword"), null], confirmPwErrorMsg),
    }),
  });

  const {
    values: values3,
    handleSubmit: handleSubmit3,
    setFieldValue: setFieldValue3,
    touched: touched3,
    errors: errors3,
  } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => getPasswordResetLink(),
    validationSchema: Yup.object().shape({
      email: Yup.string().required(reqLabel),
    }),
  });

  useEffect(() => {
    // width change
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (otpTimeout > 0) {
      const interval = setInterval(() => {
        setOtpTimeout((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpTimeout]);

  useEffect(() => {
    verifyResetPwOtp();
  }, [otp]);

  const verifyCidAndDob = () => {
    setLoading(true);
    const data = {
      cid: values1.cid,
      birthDate: moment(values1.dob).format("DD/MM/YYYY"),
    };
    authServices.getUserInfoByCidAndDob(data).then(
      (response) => {
        setLoading(false);
        setShowAlert(false);
        setFieldValue1("cidAndDobVerified", true);
        setUserInfo(response.data);
      },
      (error) => {
        setLoading(false);
        setShowAlert(true);
        setAlertData({
          severity: "warning",
          message:
            error.response &&
            error.response.data &&
            error.response.data.message,
        });
      }
    );
  };

  const handlePhoneChange = (value, country) => {
    setEnteredMobileNumber(value);
    setCountryCode(country.dialCode);
  };

  const receiveResetPasswordOtp = () => {
    setShowAlert(false);
    setShowSendOtpProgress(true);
    setOtpTimeout(otpTime);
    setOtp("");
    setSnackbar({ ...snackbar, show: false });

    const data = {
      userId: userInfo.userId,
      cid: userInfo.cid,
      dob: userInfo.dob,
      enteredMobileNumber,
    };
    authServices.receiveResetPasswordOtp(data).then(
      (response) => {
        setShowDialog(true);
        setShowSendOtpProgress(false);
        const translatedString = t("OTP code is sent to").replace(
          "%mobileNumber%",
          enteredMobileNumber
        );
        setSnackbar({
          severity: "success",
          // message: "OTP sent to +" + enteredMobileNumber,
          message: translatedString,
          show: true,
        });
      },
      (error) => {
        setShowSendOtpProgress(false);
        setShowAlert(true);
        setAlertData({
          severity: "warning",
          message:
            error.response &&
            error.response.data &&
            error.response.data.message,
        });
      }
    );
  };

  const verifyResetPwOtp = () => {
    const data = { userId: userInfo.userId, enteredMobileNumber, otp };
    if (otp.length === 6) {
      setSnackbar({ ...snackbar, show: false });

      authServices.verifyResetPwOtp(data).then(
        (response) => {
          setMobileNoVerified(true);
          setShowDialog(false);
        },
        (error) => {
          setSnackbar({
            severity: "warning",
            message: error.response.data.message,
            show: true,
          });
        }
      );
    }
  };

  const resetPasswordUsingMobileNo = () => {
    setLoading(true);
    const data = {
      userId: userInfo.userId,
      enteredMobileNumber,
      otp,
      newPassword: values2.newPassword,
      confirmPassword: values2.confirmPassword,
    };

    authServices.resetPasswordUsingMobileNo(data).then(
      (response) => {
        setLoading(false);
        setSuccess(true);
      },
      (error) => {
        setLoading(false);
        setSuccess(true);
      }
    );
  };

  const getPasswordResetLink = () => {
    setLoading(true);
    setShowAlert(false);
    let domainName = window.location.origin;
    const data = {
      userId: userInfo.userId,
      email: values3.email,
      domainName,
    };

    authServices.getPasswordResetLink(data).then(
      (response) => {
        setLoading(false);
        setShowAlert(true);
        setAlertData({
          severity: "success",
          message: response.data.message,
        });
      },
      (error) => {
        setLoading(false);
        setShowAlert(true);
        setAlertData({
          severity: "warning",
          message:
            error.response &&
            error.response.data &&
            error.response.data.message,
        });
      }
    );
  };

  const maskMobileNumber = (mobileNumber) => {
    const maskedPart = mobileNumber.slice(0, -2).replace(/\d/g, "*");
    const lastTwoDigits = mobileNumber.slice(-2);
    return maskedPart + lastTwoDigits;
    // return mobileNumber;
  };

  const maskEmail = (email) => {
    const atIndex = email.indexOf("@");
    const username = email.slice(0, atIndex);
    const maskedUsername = `${username
      .substring(0, 2)
      .padEnd(username.length, "*")}`;
    return `${maskedUsername}${email.slice(atIndex)}`;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setSnackbar({ ...snackbar, show: false });
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbar({ ...snackbar, show: false });
        }}
        action={snackbarAction}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={() => {
            setSnackbar({ ...snackbar, show: false });
          }}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Box bgcolor="white" position={"relative"}>
        <WebsiteHeader initialY={"-10vh"} animateY={"0vh"} />
        <Box
          display="flex"
          justifyContent="center"
          height="80vh"
          alignItems="center"
          position={"relative"}
        >
          <Box
            boxShadow={3}
            borderRadius={2}
            display="flex"
            flexDirection="column"
            width={{ md: "60%", sx: "100%" }}
            maxHeight="inherit"
            sx={{
              background: "rgba(255, 255, 255, 0.77)",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              "*::WebkitBackdropFilter": "blur(5px)",
              border: "1px solid rgba(255, 255, 255, 1)",
            }}
          >
            <Box
              p={{ xs: 5, sm: 5 }}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              {!success ? (
                <>
                  <Stack spacing={1}>
                    {!values1.cidAndDobVerified ? (
                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          color="text.disabled"
                          align="center"
                          variant="h6"
                        >
                          {t("Forgot Password?")}
                        </Typography>

                        <Typography align="center">
                          {t("Please enter your registered CID and DOB")}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", md: "row" }}
                          width={{ xs: "100%", md: "60%" }}
                          gap={1}
                          justifyContent="center"
                        >
                          <TextField
                            fullWidth
                            required
                            type="number"
                            label={t("CID")}
                            placeholder={t("CID")}
                            size="small"
                            disabled={loading}
                            value={values1.cid}
                            onChange={(e) =>
                              setFieldValue1("cid", e.target.value)
                            }
                            helperText={touched1.cid && errors1.cid}
                            error={errors1.cid && touched1.cid}
                            sx={{ width: { xs: "100%", md: "50%" } }}
                          />

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                              <DatePicker
                                disabled={loading}
                                fullWidth
                                inputProps={{ autoComplete: "off" }}
                                views={["year", "month", "day"]}
                                label={t("Date of Birth")}
                                inputFormat="DD/MM/YYYY"
                                maxDate={new Date()}
                                value={values1.dob}
                                onChange={(selectedDate) => {
                                  try {
                                    setFieldValue1(
                                      "dob",
                                      new Date(selectedDate)
                                    );
                                  } catch (err) {}
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    fullWidth
                                    error={touched1.dob && errors1.dob}
                                    helperText={touched1.dob && errors1.dob}
                                  />
                                )}
                              />
                              <Box height={5} />
                              <Typography
                                variant="body2"
                                sx={{ color: "grey" }}
                              >
                                {t("Format")}: dd/mm/yyyy
                              </Typography>
                            </Box>
                          </LocalizationProvider>
                        </Box>

                        <Button
                          endIcon={<ChevronRightIcon />}
                          style={{ textTransform: "initial" }}
                          color="info"
                          onClick={() => handleSubmit1()}
                          variant="outlined"
                        >
                          <Typography>{t("Next")}</Typography>
                        </Button>
                      </Stack>
                    ) : null}

                    {values1.cidAndDobVerified ? (
                      <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {userInfo.cid}{" "}
                        {t("How do you want to reset your password")}?
                        <RadioGroup
                          size
                          aria-label="anonymous"
                          onChange={(e) => {
                            setFieldValue1(
                              "resetPasswordMedium",
                              e.target.value
                            );
                          }}
                          name="anonymous"
                          value={values1.resetPasswordMedium}
                          row
                          sx={{ justifyItems: "center" }}
                        >
                          <FormControlLabel
                            value="phone"
                            control={<Radio />}
                            label={t("Using Mobile Number")}
                          />
                          <FormControlLabel
                            value="email"
                            control={<Radio />}
                            label={t("Using Email")}
                          />
                        </RadioGroup>
                      </Stack>
                    ) : null}
                    {values1.resetPasswordMedium === "phone" ? (
                      <>
                        <Stack
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h5">
                            {t("Get a verification code")}
                          </Typography>
                          <Typography>
                            {userInfo.mobileNo === null ? (
                              <Alert icon={false} severity="info">
                                <Typography>
                                  {t(
                                    "You have not entered your phone number. Please add phone number in order to get verification code."
                                  )}
                                </Typography>
                              </Alert>
                            ) : (
                              <Typography>
                                {t(
                                  "To get a verification code, first confirm the phone number you added to your account"
                                )}{" "}
                                {maskMobileNumber(userInfo.mobileNo)}
                              </Typography>
                            )}
                          </Typography>
                        </Stack>
                        {userInfo.mobileNo !== null && (
                          <Stack
                            flexDirection={{ xs: "column", md: "row" }}
                            gap={2}
                            justifyContent="center"
                            marginTop={2}
                            // alignItems="center"
                          >
                            <Box>
                              <div className="phone-input-container">
                                <PhoneInput
                                  inputStyle={{ width: "100%" }}
                                  enableSearch
                                  country={"bt"}
                                  value={enteredMobileNumber}
                                  onChange={handlePhoneChange}
                                  disabled={
                                    showSendOtpProgress || mobileNoVerified
                                  }
                                />
                                <label
                                  htmlFor="phone-input"
                                  className={`floating-label ${
                                    enteredMobileNumber ? "filled" : ""
                                  }`}
                                >
                                  {t("Mobile Number")}*
                                </label>
                              </div>
                              <CustomTooltip
                                text={t(
                                  "Only Bhutanese mobile numbers will receive the OTP."
                                )}
                              >
                                <Typography variant="body2">
                                  <InfoIcon color="info" />
                                </Typography>
                              </CustomTooltip>
                            </Box>
                            <Box>
                              <Box sx={{ display: "flex", gap: "10px" }}>
                                {countryCode === "975" &&
                                enteredMobileNumber.length > 7 ? (
                                  <Box>
                                    {!mobileNoVerified ? (
                                      <Button
                                        endIcon={<SendIcon />}
                                        disabled={
                                          mobileNoVerified ||
                                          showSendOtpProgress
                                        }
                                        variant="outlined"
                                        onClick={receiveResetPasswordOtp}
                                        color="info"
                                        style={{ textTransform: "initial" }}
                                      >
                                        <Typography>{t("Send OTP")}</Typography>
                                        {showSendOtpProgress && (
                                          <CircularProgress size="1rem" />
                                        )}
                                      </Button>
                                    ) : (
                                      <Typography noWrap>
                                        <TaskAltIcon
                                          style={{ fontSize: 23 }}
                                          color="success"
                                        />
                                        {t("Verified")}
                                      </Typography>
                                    )}
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Box>
                          </Stack>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {values1.resetPasswordMedium === "email" ? (
                      <>
                        <Stack
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h5">
                            {t("Get a verification code")}
                          </Typography>
                          <Typography>
                            {userInfo.email === null ? (
                              <Alert icon={false} severity="info">
                                <Typography align="justify">
                                  {t(
                                    "You have not entered your email. Please add  email in order to get verification code."
                                  )}
                                </Typography>
                              </Alert>
                            ) : (
                              <Typography>
                                {t(
                                  "To get a verification code, first confirm the email you added to your account"
                                )}{" "}
                                {maskEmail(userInfo.email)}
                              </Typography>
                            )}
                          </Typography>
                        </Stack>
                        {userInfo.email !== null && (
                          <Stack
                            gap={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              fullWidth
                              required
                              type="email"
                              label={t("Email")}
                              placeholder={t("Email")}
                              size="small"
                              disabled={loading}
                              value={values3.email}
                              onChange={(e) =>
                                setFieldValue3("email", e.target.value)
                              }
                              helperText={touched3.email && errors3.email}
                              error={errors3.email && touched3.email}
                              sx={{ width: { xs: "100%", md: "50%" } }}
                            />
                            <Button
                              disabled={loading}
                              onClick={handleSubmit3}
                              color="info"
                              variant="outlined"
                              style={{ textTransform: "initial" }}
                            >
                              <Typography>{t("Send Reset Link")}</Typography>
                              {loading && <CircularProgress size="1rem" />}
                            </Button>
                          </Stack>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {mobileNoVerified &&
                    values1.resetPasswordMedium === "phone" ? (
                      <Box
                        gap={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography align="justify">
                          {t("Please create a password now.")}{" "}
                          {t("Check the password policy here.")}
                        </Typography>
                        <TextField
                          inputProps={{ maxLength: 30, autoComplete: "off" }}
                          type={
                            showPassword.showNewPassword
                              ? undefined
                              : "password"
                          }
                          required
                          label={t("New Password")}
                          placeholder={t("New Password")}
                          size="small"
                          disabled={loading}
                          value={values2.newPassword}
                          onChange={(e) =>
                            setFieldValue2("newPassword", e.target.value)
                          }
                          helperText={
                            touched2.newPassword && errors2.newPassword
                          }
                          error={errors2.newPassword && touched2.newPassword}
                          sx={{ width: { xs: "100%", md: "50%" } }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                <IconButton
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      showNewPassword:
                                        !showPassword.showNewPassword,
                                    })
                                  }
                                >
                                  {showPassword.showNewPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          inputProps={{ maxLength: 30, autoComplete: "off" }}
                          type={
                            showPassword.showConfirmPassword
                              ? undefined
                              : "password"
                          }
                          required
                          label={t("Confirm Password")}
                          placeholder={t("Confirm Password")}
                          size="small"
                          disabled={loading}
                          value={values2.confirmPassword}
                          onChange={(e) =>
                            setFieldValue2("confirmPassword", e.target.value)
                          }
                          helperText={
                            touched2.confirmPassword && errors2.confirmPassword
                          }
                          error={
                            errors2.confirmPassword && touched2.confirmPassword
                          }
                          sx={{ width: { xs: "100%", md: "50%" } }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                <IconButton
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      showConfirmPassword:
                                        !showPassword.showConfirmPassword,
                                    })
                                  }
                                >
                                  {showPassword.showConfirmPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          disabled={loading}
                          onClick={handleSubmit2}
                          color="info"
                          variant="outlined"
                          style={{ textTransform: "initial" }}
                        >
                          <Typography>{t("Reset Now")}</Typography>
                          {loading && <CircularProgress size="1rem" />}
                        </Button>
                      </Box>
                    ) : null}
                  </Stack>
                </>
              ) : (
                <>
                  <Alert severity="success">
                    <Typography align="center">
                      {t(
                        "You have successfully changed your password. Please login using your new password"
                      )}{" "}
                      <Link to="/signIn">{t("Click here to login")}</Link>
                    </Typography>
                  </Alert>
                </>
              )}

              <Collapse in={showAlert}>
                <Alert
                  severity={alertData.severity}
                  action={
                    <IconButton onClick={() => setShowAlert(false)}>
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  <Typography>{alertData.message}</Typography>
                </Alert>
              </Collapse>
            </Box>
          </Box>
        </Box>
      </Box>
      {/*otp dialog*/}
      <Dialog
        open={showDialog}
        PaperProps={{ style: { backgroundColor: "#f3f3f3" } }}
      >
        <Box display={"block"}>
          <DialogTitle>
            <Typography textAlign="center">
              {t("Please enter OTP in")}{" "}
              <span style={{ color: "red" }}>{formatTime(otpTimeout)}</span>{" "}
              {t("MMSS")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {otpTimeout > 0 ? (
                <OtpInput
                  value={otp}
                  onChange={(code) => setOtp(code)}
                  numInputs={6}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: isMobileView ? screenWidth * 0.1 : "54px",
                    height: isMobileView ? screenWidth * 0.1 : "54px",
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "400",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                  shouldAutoFocus={true}
                />
              ) : (
                <>
                  <Alert severity="warning">
                    <Typography align="justify">
                      {t(
                        "Time lasped. Please request a new OTP. Click Resend if the mobile number is the same."
                      )}
                    </Typography>
                  </Alert>
                </>
              )}

              <Typography textAlign="center">
                {(() => {
                  const translatedString = t("OTP code is sent to").replace(
                    "%mobileNumber%",
                    enteredMobileNumber
                  );
                  return translatedString;
                })()}{" "}
                {t("Didn't receive")}?
                <Button
                  variant="text"
                  style={{ textTransform: "initial" }}
                  onClick={receiveResetPasswordOtp}
                >
                  <Typography style={{ color: "#0088d2" }}>
                    {t("Resend")}
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </DialogContent>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "initial" }}
            variant="outlined"
            color="warning"
            onClick={() => {
              setShowDialog(false);
              setShowSendOtpProgress(false);
            }}
          >
            <Typography>{t("Close")}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
