import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class UserService {
  getCensusDetailByCid(cid) {
    return axios.get(
      BASE_URL + "api/user/profile/saUser/getCensusDetailByCid",
      {
        params: { cid },
        headers: authHeader(),
      }
    );
  }

  getUsers() {
    return axios.get(BASE_URL + "api/user/profile/saUser/getUsers", {
      headers: authHeader(),
    });
  }

  addUser(data) {
    return axios.post(BASE_URL + "api/user/profile/saUser/addUser", data, {
      headers: authHeader(),
    });
  }

  editUser(data) {
    return axios.post(BASE_URL + "api/user/profile/saUser/editUser", data, {
      headers: authHeader(),
    });
  }

  getSignUpUsersByCriteria(signupYear,country,gender,cid,fromDate,toDate,presentGeogId,presentDzongkhagId,offset,
                           pageSize,dobYear) {
    return axios.get(BASE_URL + "api/user/profile/userProfile/getUserInfoByCriteria",
    {
      params: { signupYear,country, gender,cid,fromDate,toDate,presentGeogId,presentDzongkhagId,offset,
        pageSize,dobYear},
      headers: authHeader(),
    });
  }

  getRemainingSignupUser(signupYear,gender,cid,offset, pageSize) {
    return axios.get(BASE_URL + "api/user/profile/userProfile/getRemainingSignupUser",
        {
          params: { signupYear,gender,cid,offset, pageSize},
          headers: authHeader(),
        });
  }
}

export default new UserService();
