import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import useComponentBasePremission from "../../CustomHooks/useComponetBasePermission";
import { useEffect } from "react";

const ButtonType1 = ({ onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Button
      size="small"
      variant="outlined"
      sx={{
        boxShadow: "1",
        color: theme.palette.mode !== "dark" ? colors.main[900] : "white",
        borderColor: theme.palette.mode !== "dark" ? colors.main[900] : "white",
        "&:hover": {
          borderColor:
            theme.palette.mode !== "dark" ? colors.main[900] : "white",
          backgroundColor:
            theme.palette.mode !== "dark" ? colors.main[900] : "white",
          color: theme.palette.mode !== "dark" ? "white" : colors.primary[400],
        },
      }}
      onClick={onClick}
    >
      Add
    </Button>
  );
};

export default ButtonType1;
