import {motion} from "framer-motion";
import {useTheme} from "@mui/material";

const PageTransitionFadeIn = ({child}) => {
    return <motion.div initial={{opacity: 0}}
                       animate={{opacity: 1}}
                       transition={{delay: 0.2}}
    >
        {child}
    </motion.div>
}

export default PageTransitionFadeIn