import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import corner from "../../assets/border/border.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BallotIcon from "@mui/icons-material/Ballot";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import GroupIcon from "@mui/icons-material/Group";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import QuizIcon from "@mui/icons-material/Quiz";
import LockResetIcon from "@mui/icons-material/LockReset";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import StartIcon from "@mui/icons-material/Start";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import bg2 from "../../assets/background/side-menu-bg-1.png";
import { motion } from "framer-motion";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import SubjectIcon from "@mui/icons-material/Subject";
import Collapse from "@mui/material/Collapse";
import { pathGenerator } from "../../helper/PathGenerator";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import logo from "../../assets/logo/logo-gyalsung.png";

const SideMenu = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const drawerWidth = 270;

  const [allowedPages, setAllowedPages] = useState([]);

  const iconsToMenuItem = {
    Course: <SubjectIcon style={{ color: "white" }} />,
    "Master Data": <BedroomParentIcon style={{ color: "white" }} />,
    Medical: <MonitorHeartIcon style={{ color: "white" }} />,
    "Gyalsung Registration Date Setup": (
      <PunchClockIcon style={{ color: "white" }} />
    ),
    Dashboard: <DashboardIcon style={{ color: "white" }} />, // dashboard
    "Parent Consent": <ArticleIcon style={{ color: "white" }} />, // Submit parent consent
    "Submit Parent Consent": <ArticleIcon style={{ color: "white" }} />, // Submit parent consent
    "Parent Consent List": <ListAltIcon style={{ color: "white" }} />, // parent consent list
    "Enrollment Config": <FollowTheSignsIcon style={{ color: "white" }} />, // enrollment
    Enrollment: <FollowTheSignsIcon style={{ color: "white" }} />, // enrollment
    "Enrollment List": <BallotIcon style={{ color: "white" }} />, // enrollment list
    "Apply Deferment": <StartIcon style={{ color: "white" }} />, // apply deferment
    Deferment: <LowPriorityIcon style={{ color: "white" }} />, // deferment list
    "Deferment List": <LowPriorityIcon style={{ color: "white" }} />, // deferment list
    "Apply Exemption": <DisabledByDefaultIcon style={{ color: "white" }} />, // apply exemption
    Exemption: <ContentPasteOffIcon style={{ color: "white" }} />, // exemption list
    "Exemption List": <ContentPasteOffIcon style={{ color: "white" }} />, // exemption list
    "Medical Booking": <EnhancedEncryptionIcon style={{ color: "white" }} />, // medical booking
    "Edit Medical Booking": <BloodtypeIcon style={{ color: "white" }} />, // edit medical booking
    "Appointment List": <ClearAllIcon style={{ color: "white" }} />, // appointment list
    "Training Academy": <HolidayVillageIcon style={{ color: "white" }} />, // training academy
    "Academy Wise Enrollment": <GroupAddIcon style={{ color: "white" }} />, // academy wise enrollment
    Users: <GroupIcon style={{ color: "white" }} />, // users
    "Role Wise Access Permission": (
      <VisibilityOffIcon style={{ color: "white" }} />
    ), // RoleWiseAccessPermission
    "Send Notifications": <EditNotificationsIcon style={{ color: "white" }} />, // send notifications
    "Enlistment Date Setup": <CalendarMonthIcon style={{ color: "white" }} />, // enlistment date setup
    "Field Specialization": <SmartButtonIcon style={{ color: "white" }} />, // field specialization
    "Medical Category Setup": <LocalPharmacyIcon style={{ color: "white" }} />, // medical category setup
    "Medical Questionnaire setup": <QuizIcon style={{ color: "white" }} />, // medical questionnaire setup
    "Reset User Password": <LockResetIcon style={{ color: "white" }} />, // reset user password
    "Training Academy Intake": <AddBusinessIcon style={{ color: "white" }} />, // training academy intake
    "Notice Configuration": <AddAlertIcon style={{ color: "white" }} />, // notice configuration
    "Dzongkhag Hospital Mapping": (
      <AccountTreeIcon style={{ color: "white" }} />
    ), //dzongkhag hospital mapping
    "Hospital Schedule Time Setup": <MoreTimeIcon style={{ color: "white" }} />, // hospital schedule time setup
    "Hospital Schedule Time List": <ScheduleIcon style={{ color: "white" }} />, // hospital schedule time list
    Reason: <CardMembershipIcon style={{ color: "white" }} />, // reason
    "Registration Date Setup": (
      <FormatIndentIncreaseIcon style={{ color: "white" }} />
    ), // registration date setup
    "Screen Group Setup": (
      <FormatIndentIncreaseIcon style={{ color: "white" }} />
    ), // Screen Group setup
    "Screen Setup": <FormatIndentIncreaseIcon style={{ color: "white" }} />, // Screen  setup
  };

  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = useState({});

  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [selectedMenuGroup, setSelectedMenuGroup] = useState("");
  const [routeMapping, setRouteMapping] = useState({});

  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("user"));
    let tmpSideMenuItem = u.accessScreens;
    const seenScreenIds = new Set();
    tmpSideMenuItem = tmpSideMenuItem.filter((item) => {
      if (!seenScreenIds.has(item.screen_id)) {
        seenScreenIds.add(item.screen_id);
        return true;
      }
      return false;
    });
    const data = {};
    let fData = [];
    const tmpIsCollapse = {};
    const tL = [];
    const tmpRouteMapping = {};
    for (const i in tmpSideMenuItem) {
      tmpRouteMapping[tmpSideMenuItem[i]["screen_name"]] =
        tmpSideMenuItem[i]["screen_url"];
      // if it is part of menu item group
      if (
        ![
          "gyalsup profile",
          "edit mobile number",
          "edit parent info",
          "enlistment date setup",
          "edit email address",
          "edit password",
          "edit social media link",
          "edit census record",
          "edit current user",
          "edit user name",
          "edit guardian info",
          "edit current address",
          "expected population dashboard",
          "expected population male dashboard",
          "expected population female dashboard",
          "sign up population dashboard",
        ].includes(tmpSideMenuItem[i]["screen_name"].toLowerCase())
      ) {
        if (Object.keys(tmpSideMenuItem[i]).includes("screen_group_name")) {
          // check if screen group name already exist
          if (
            Object.keys(data).includes(tmpSideMenuItem[i]["screen_group_name"])
          ) {
            if (!tL.includes(tmpSideMenuItem[i]["screen_name"])) {
              data[tmpSideMenuItem[i]["screen_group_name"]] = [
                ...data[tmpSideMenuItem[i]["screen_group_name"]],
                tmpSideMenuItem[i]["screen_name"],
              ];
              tL.push(tmpSideMenuItem[i]["screen_name"]);
            }
          } else {
            data[tmpSideMenuItem[i]["screen_group_name"]] = [
              tmpSideMenuItem[i]["screen_name"],
            ];
          }
        } else {
          data[tmpSideMenuItem[i]["screen_name"]] = [
            tmpSideMenuItem[i]["screen_name"],
          ];
        }
      }
    }
    for (const key in data) {
      // sort element under group
      data[key] = data[key].sort();
      tmpIsCollapse[key] = false;
    }
    // const cleanedMenuData = [];
    if (Object.keys(data).length > 0) {
      Object.keys(data)
        .sort()
        .forEach((key) => {
          if (key === "Dashboard") {
            fData.splice(0, 0, { [key]: data[key] });
          } else {
            fData.push({ [key]: data[key] });
          }
        });
    }

    // when group has only one view allowed in the group and rest are not viewable
    for (const i in fData) {
      if (fData[i][Object.keys(fData[i])[0]].length === 1) {
        if (
          fData[i][Object.keys(fData[i])[0]][0] !== Object.keys(fData[i])[0]
        ) {
          fData = Object.assign([], fData, {
            [i]: {
              [fData[i][Object.keys(fData[i])[0]]]:
                fData[i][Object.keys(fData[i])[0]],
            },
          });
        }
      }
    }
    setRouteMapping(tmpRouteMapping);
    setIsCollapsed(tmpIsCollapse);
    setAllowedPages(fData);
    setSelectedMenuGroup("Dashboard");
    setSelectedMenuItem("Dashboard");
  }, []);

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        backgroundColor: colors.main[900],
        height: "100vh",
        paddingY: 0,
        "*::-webkit-scrollbar": {
          width: "3px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background:
            theme.palette.mode === "dark" ? "#1D388F61" : colors.main[900],
        },
      }}
      aria-label="mailbox folders"
    >
      {/* Desktop */}
      <motion.aside
        initial={{ x: -240 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Drawer
          PaperProps={{
            sx: {
              backgroundImage: `url(${bg2})`,
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundPosition: "left",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {/*top bar*/}
          <Toolbar
            sx={{
              backgroundColor: "white",
              position: "fixed",
              boxShadow: 1,
              zIndex: 100,
              width: `${drawerWidth}px`,
              background: "linear-gradient(to right, rgb(4 4 4), rgb(4 4 4))",
            }}
          >
            <Stack direction="row" alignItems="center">
              {/* <img src={corner} alt="" height={50} /> */}
              <img
                src={logo}
                alt="logo"
                width={"70%"}
                // height={"60%"}
                // style={{ maxWidth: "100%", paddingBottom: "75%" }}
              />
              {/* <Typography variant="h3" align="justify">
                Gyalsung  
              </Typography> */}
            </Stack>
          </Toolbar>
          {/* dark filter and list of menu items */}
          <Box
            sx={{
              backgroundColor: colors.main[900],
              backgroundImage: `url(${bg2})`,
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundPosition: "left",
              position: "relative",
            }}
          >
            {/*overlay filter*/}
            <Box
              position={"fixed"}
              height={"100vh"}
              width={`${drawerWidth}px`}
              bgcolor={"rgba(48, 47, 47, 0.8)"}
              overflow={"clip"}
            />
            <Divider />
            {/* <Toolbar /> */}
            {/*list view*/}
            <List
              sx={{
                pt: `${theme.mixins.toolbar.minHeight + 5}px`,
                height: "100vh",
                overflow: "auto",
              }}
              dense
            >
              {allowedPages.map((item, index) => {
                return Object.values(item)[0].length === 1 ? (
                  <ListItemButton
                    key={Object.keys(item)[0]}
                    sx={{
                      backgroundColor:
                        selectedMenuItem === Object.keys(item)[0]
                          ? theme.palette.mode === "dark"
                            ? "#868dfb !important"
                            : colors.main[900]
                          : null,
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#868dfb !important"
                            : colors.main[900],
                      },
                    }}
                    onClick={() => {
                      setSelectedMenuGroup(Object.keys(item)[[0]]);
                      setSelectedMenuItem(Object.keys(item)[0]);
                      // console.log(routeMapping[Object.keys(item)[[0]]]);
                      navigate(`./${routeMapping[Object.keys(item)[[0]]]}`);
                    }}
                  >
                    <ListItemIcon>
                      {iconsToMenuItem[Object.keys(item)[0]]}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color={"white"}>
                        {Object.keys(item)[0]}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                ) : (
                  <Box key={Object.keys(item)[0]}>
                    <ListItemButton
                      onClick={() => {
                        setSelectedMenuGroup(Object.keys(item)[0]);
                        setSelectedMenuItem(Object.keys(item)[0]);
                        setIsCollapsed({
                          ...isCollapsed,
                          [Object.keys(item)[0]]:
                            !isCollapsed[Object.keys(item)[0]],
                        });
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#868dfb !important"
                              : colors.main[900],
                        },
                        backgroundColor:
                          selectedMenuGroup === Object.keys(item)[0]
                            ? theme.palette.mode === "dark"
                              ? "#868dfb !important"
                              : colors.main[900]
                            : null,
                      }}
                    >
                      <ListItemIcon>
                        {iconsToMenuItem[Object.keys(item)[0]]}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          color={"white"}
                          fontWeight={
                            isCollapsed[Object.keys(item)[0]] ? "bold" : null
                          }
                          sx={{
                            display: "inline",
                            textDecoration: isCollapsed[Object.keys(item)[0]]
                              ? "underline"
                              : null,
                          }}
                        >
                          {Object.keys(item)[0]}
                        </Typography>
                      </ListItemText>
                      {isCollapsed[Object.keys(item)[0]] ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItemButton>
                    <Collapse
                      sx={{ pl: 3 }}
                      in={isCollapsed[Object.keys(item)[0]]}
                    >
                      {Object.values(item)[0].map((child, index) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <ListItemButton
                              sx={{
                                "&:hover": {
                                  backgroundColor:
                                    theme.palette.mode === "dark"
                                      ? "#868dfb !important"
                                      : colors.primary[700],
                                },
                                backgroundColor:
                                  selectedMenuItem === child
                                    ? theme.palette.mode === "dark"
                                      ? "#868dfb !important"
                                      : colors.primary[700]
                                    : null,
                              }}
                              onClick={() => {
                                setSelectedMenuItem(child);
                                navigate(`./${routeMapping[child]}`);
                              }}
                            >
                              {/* icon */}
                              <ListItemIcon>
                                {/* {iconsToMenuItem[child]} */}
                                <HorizontalRuleIcon
                                  style={{ color: "white" }}
                                />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography color={"white"}>{child}</Typography>
                              </ListItemText>
                            </ListItemButton>
                          </Box>
                        );
                      })}
                    </Collapse>
                  </Box>
                );
              })}
            </List>
            <Divider />
          </Box>
        </Drawer>
      </motion.aside>
    </Box>
  );
};

export default SideMenu;
