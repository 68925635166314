import {
    AppBar,
    Box,
    Button,
    Container,
    Fab,
    IconButton,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ColorModeContext, tokens} from "../../theme";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {motion, useAnimationControls, useScroll} from "framer-motion";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const WebsiteFooter = () => {
    let navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const animationControls = useAnimationControls()

    return <Box sx={{
        backgroundColor: '#1F3667',
        height: '110px',
        // transform: 'translateY(10px)',
        display: 'flex',
        alignItems: 'center',
        borderTop: 'dashed grey',
    }}>
        <Container maxWidth={'md'}>
            <Stack color={'white'} direction={'row'} spacing={2}>
                <Typography><strong>Privacy Statement</strong></Typography>
                <Typography><strong>Term of Use</strong></Typography>
            </Stack>
        </Container>
    </Box>
}

export default WebsiteFooter;