import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const validateGuardianConsentLink = (data) => {
  return axios.post(
    BASE_URL +
      "api/enrolment/deferment/exemption/guardianConsent/validateGuardianConsentLink",
    data
  );
};

const validateGuardian = (data) => {
  return axios.post(
    BASE_URL +
      "api/enrolment/deferment/exemption/guardianConsent/validateGuardian",
    data
  );
};
const receiveOtp = (data) => {
  return axios.post(
    BASE_URL + "api/enrolment/deferment/exemption/guardianConsent/receiveOtp",
    data
  );
};

const verifyOtp = (data) => {
  return axios.post(
    BASE_URL + "api/enrolment/deferment/exemption/guardianConsent/verifyOtp",
    data
  );
};

const grantGuardianConsent = (data) => {
  return axios.post(
    BASE_URL +
      "api/enrolment/deferment/exemption/guardianConsent/grantGuardianConsent",
    data
  );
};

const denyGuardianConsent = (data) => {
  return axios.post(
    BASE_URL +
      "api/enrolment/deferment/exemption/guardianConsent/denyGuardianConsent",
    data
  );
};


const getGuardianConsentListByUserId = (userId,enlistmentYear) => {
  return axios.get(
      BASE_URL + "api/enrolment/deferment/exemption/guardianConsent/getGuardianConsentListByUserId",
      {
        params: {userId,enlistmentYear},
        headers: authHeader(),
      }
  );
};

export default {
  validateGuardianConsentLink,
  validateGuardian,
  receiveOtp,
  verifyOtp,
  grantGuardianConsent,
  denyGuardianConsent,
  getGuardianConsentListByUserId
};
