import { Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import React from "react";

const GuardianConsent = (props) => {
    const guardianInfo = props.guardianInfo;
    const pendingStatus = "P";
    const rejectStatus = "R";
    const cancelled = "C";
    const userInfo = props.userInfo;
    const dateFormat = props.dateFormat;
    const timeFormat = props.timeFormat;
    const getConsentStatusName = props.getConsentStatusName;
    const setShowRequestConsentDialog = props.setShowRequestConsentDialog;
    const preLatestEnlistmentData = props.preLatestEnlistmentData;

    return (
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            {(guardianInfo.status === pendingStatus ||
                guardianInfo.status === rejectStatus ||
                guardianInfo.status === undefined || preLatestEnlistmentData.status === cancelled
                || preLatestEnlistmentData.status === rejectStatus
                || (preLatestEnlistmentData.status === "D" && guardianInfo.status === pendingStatus))? (
                <>
                    {(guardianInfo.status === pendingStatus || guardianInfo.status === rejectStatus
                        || (preLatestEnlistmentData.status === "D" && guardianInfo.status === pendingStatus))  ? (
                        <Stack sx={{ width: "100%" }} spacing={1}>
                            <Alert icon={false} severity="warning" variant="outlined">
                                <Typography>
                                    You have requested parent/guardian consent to following:
                                </Typography>
                                <Typography>
                                    Status: {getConsentStatusName(guardianInfo.status)}
                                </Typography>
                                <Typography>
                                    Guardian Name: <strong>{guardianInfo.guardianName}</strong>
                                </Typography>
                                <Typography>
                                    Guardian Mobile No:{" "}
                                    <strong color="error">{guardianInfo.guardianMobileNo}</strong>
                                </Typography>
                                <Typography>
                                    Guardian Email: <strong>{guardianInfo.guardianEmail}</strong>
                                </Typography>
                                <Typography>
                                    Requested on:{" "}
                                    <strong>
                                        {moment(guardianInfo.consentRequestDate).format(dateFormat)}
                                        {", at "}
                                        {moment(guardianInfo.consentRequestDate).format(timeFormat)}
                                    </strong>
                                </Typography>

                                <Typography>
                                    To request consent from your primary guardian again{" "}
                                    <Button
                                        variant="outlined"
                                        color="info"
                                        style={{ textTransform: "initial" }}
                                        onClick={() => {
                                            setShowRequestConsentDialog(true);
                                        }}
                                    >
                                        <Typography>Click Here</Typography>
                                    </Button>
                                </Typography>
                            </Alert>
                        </Stack>
                    ) : (
                        <>
                            <Stack spacing={1}>
                                <Typography>
                                    Your parent's or guardian's consent is required. Your
                                    application cannot be evaluated without their consent. An
                                    email will be sent to your primary guardian below:
                                </Typography>
                                <Typography>
                                    Name: <strong>{userInfo.guardianNameFirst}</strong>
                                </Typography>
                                <Typography>
                                    Mobile No: <strong>{userInfo.guardianMobileNoFirst}</strong>
                                </Typography>
                                <Typography>
                                    Email: <strong>{userInfo.guardianEmailFirst}</strong>
                                </Typography>
                                <Typography>
                                    You may update the{" "}
                                    <Link to="../gyalsupProfile/editGuardianInfo">
                                        {"primary guardian"}
                                    </Link>{" "}
                                    details in your profile if he or she is not your primary
                                    guardian before you request consent.
                                </Typography>
                                <br/>
                                <Typography>
                                    To request consent from your primary guardian{" "}
                                    <Button
                                        variant="outlined"
                                        color="info"
                                        style={{ textTransform: "initial" }}
                                        onClick={() => {
                                            setShowRequestConsentDialog(true);
                                        }}
                                    >
                                        <Typography>Click Here</Typography>
                                    </Button>
                                </Typography>
                            </Stack>
                        </>
                    )}
                </>
            ) : (
                <Stack sx={{ width: "100%" }} spacing={1}>
                    <Alert icon={false} severity="success" variant="outlined">
                        <Typography>
                            You have requested parent/guardian consent to following:
                        </Typography>
                        <Typography>
                            Status: {getConsentStatusName(guardianInfo.status)}
                        </Typography>
                        <Typography>
                            Guardian Name: <strong>{guardianInfo.guardianName}</strong>
                        </Typography>
                        <Typography>
                            Guardian Mobile No:{" "}
                            <strong color="error">{guardianInfo.guardianMobileNo}</strong>
                        </Typography>
                        <Typography>
                            Guardian Email: <strong>{guardianInfo.guardianEmail}</strong>
                        </Typography>
                        <Typography>
                            Requested on:{" "}
                            <strong>
                                {moment(guardianInfo.consentRequestDate).format(dateFormat)}
                                {", at "}
                                {moment(guardianInfo.consentRequestDate).format(timeFormat)}
                            </strong>
                        </Typography>
                    </Alert>
                </Stack>
            )}
        </Stack>
    );
};
export default GuardianConsent;
