import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import pako from "pako";
import fileDownload from "js-file-download";
import {PDFDocument} from "pdf-lib";
import {Chip, Typography} from "@mui/material";
import React from "react";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import moment from "moment";
import userRoles from "../../data/userRoles.json";
import commonService from "../../services/common.service";

class CommonMethod {
    getFileIcon = (fileName) => {
        const parts = fileName.split(".");
        let extension = parts[parts.length - 1];
        let fileIcon = <PictureAsPdfOutlinedIcon style={{ color: "#b36161" }} />;
        if (
            extension.toLowerCase() === "jpg" ||
            extension.toLowerCase() === "jpeg" ||
            extension.toLowerCase() === "png"
        ) {
            fileIcon = <InsertPhotoOutlinedIcon style={{ color: "#4aca41" }} />;
        }
        return fileIcon;
    };

    previewDocument = async (file) => {
        // Assuming 'file.file' contains a previously base64-encoded compressed string
        const base64CompressedString = file.file;

        // Decode the base64 string to get the compressed binary data
        const compressedData = Uint8Array.from(atob(base64CompressedString), (c) =>
            c.charCodeAt(0)
        );

        // Decompress the Uint8Array using pako (assuming it was previously compressed using pako.deflate)
        try {
            const decompressedData = pako.inflate(compressedData);
            // Determine the content type based on the file extension or type
            let contentType;
            const fileName = file.fileName.toLowerCase();

            if (fileName.endsWith(".pdf")) {
                contentType = "application/pdf";
            } else if (
                fileName.endsWith(".jpg") ||
                fileName.endsWith(".jpeg") ||
                fileName.endsWith(".png")
            ) {
                contentType = "image/jpeg"; // Adjust as needed for other image formats
            } else {
                contentType = "application/octet-stream"; // Fallback content type
            }

            // Create a Blob from the decompressed data with the determined content type
            const blob = new Blob([decompressedData], { type: contentType });

            // Create a URL for the Blob
            const objectURL = URL.createObjectURL(blob);

            // Open the content in a popup window
            window.open(objectURL, "PopupWindow", "width=800, height=600");
        } catch (error) {
            console.error("Decompression error:", error);
        }
    };

    //Method to download file
    downloadDocument = async (file,fileName) => {
        const base64CompressedString = file.file;

        const compressedData = Uint8Array.from(atob(base64CompressedString), (c) =>
            c.charCodeAt(0)
        );
        try {
            const decompressedData = pako.inflate(compressedData);
            fileDownload(decompressedData, fileName);
        } catch (error) {
            console.error("Decompression error:", error);
        }
    };

    //Method to show files in one pdf
    showPfdInOneFile = async (fileList) => {
        const mimeTypes = {
            txt: "text/plain",
            html: "text/html",
            jpg: "image/jpeg",
            jpeg: "image/jpeg",
            png: "image/png",
            // Add more mappings as needed
        };

        const mergedPDF = await PDFDocument.create();

        for (const file of fileList) {
            const base64CompressedString = file.file;

            const fileExtension = file.fileName.slice(
                ((file.fileName.lastIndexOf(".") - 1) >>> 0) + 2
            );

            // Look up the MIME type based on the file extension
            const type = mimeTypes[fileExtension.toLowerCase()] || "application/pdf";
            // Decode the base64 string to get the compressed binary data
            const compressedData = Uint8Array.from(
                atob(base64CompressedString),
                (c) => c.charCodeAt(0)
            );

            // Decompress the Uint8Array using pako (assuming it was previously compressed using pako.deflate)
            try {
                const decompressedData = pako.inflate(compressedData);
                let image;
                if (type === "application/pdf") {
                    const externalPDF = await PDFDocument.load(decompressedData, {
                        ignoreEncryption: true,
                    });
                    const copiedPages = await mergedPDF.copyPages(
                        externalPDF,
                        externalPDF.getPageIndices()
                    );
                    copiedPages.forEach((page) => mergedPDF.addPage(page));
                } else if (type === "image/jpeg") {
                    image = await mergedPDF.embedJpg(decompressedData);
                    const page = mergedPDF.addPage();
                    page.drawImage(image, {
                        x: 0,
                        y: 0,
                        width: page.getWidth(),
                        height: page.getHeight(),
                    });
                } else if (type === "image/png") {
                    image = await mergedPDF.embedPng(decompressedData);
                    const page = mergedPDF.addPage();
                    page.drawImage(image, {
                        x: 0,
                        y: 0,
                        width: page.getWidth(),
                        height: page.getHeight(),
                    });
                }
            } catch (error) {
                console.error("Decompression error:", error);
            }
        }

        const mergedPDFBytes = await mergedPDF.save();
        const mergedPDFURL = URL.createObjectURL(
            new Blob([mergedPDFBytes], { type: "application/pdf" })
        );

        window.open(mergedPDFURL);
    };

    displayStatusName = (status) => {
        let statusName = (
            <Chip
                size="small"
                label={<Typography>Pending</Typography>}
                color="warning"
                variant="outlined"
            />
        );
        if (status === "C") {
            statusName = (
                <Chip
                    size="small"
                    label={<Typography>Cancelled</Typography>}
                    color="primary"
                    variant="outlined"
                />
            );
        } else if (status === "V") {
            statusName = (
                <Chip
                    size="small"
                    label={<Typography>Reverted</Typography>}
                    color="error"
                    variant="outlined"
                />
            );
        } else if (status === "T") {
            statusName = (
                <Chip
                    size="small"
                    label={<Typography>Reviewed</Typography>}
                    color="success"
                    variant="outlined"
                />
            );
        } else if (status === "A") {
            statusName = (
                <Chip
                    size="small"
                    label={<Typography>Approved</Typography>}
                    color="success"
                    variant="outlined"
                />
            );
        } else if (status === "R") {
            statusName = (
                <Chip
                    size="small"
                    label={<Typography>Rejected</Typography>}
                    color="error"
                    variant="outlined"
                />
            );
        }
        return statusName;
    };


    displayMailStatusName = (status) => {
        let statusName = (
            <Chip
                size="small"
                label={
                    <Typography>
                        <MarkEmailReadOutlinedIcon />
                        Yes
                    </Typography>
                }
                color="success"
                variant="outlined"
            />
        );
        if (status === "N" || status === null) {
            statusName = (
                <Chip
                    size="small"
                    label={
                        <Typography>
                            <MarkEmailUnreadOutlinedIcon />
                            No
                        </Typography>
                    }
                    color="warning"
                    variant="outlined"
                />
            );
        }
        return statusName;
    };

    getUserRole = (userTypes) => {

        const seniorUserType = "Senior Officer";
        const medicalUserType = "Medical Officer";
        const nonMedicalUserType = "Non Medical Officer";

        if (
            (userTypes.some((userType) => userType.includes(Object.keys(userRoles).find(key => userRoles[key] === medicalUserType))) ||
                userTypes.some((userType) => userType.includes(Object.keys(userRoles).find(key => userRoles[key] === nonMedicalUserType)))) &&
            userTypes.some((userType) => userType.includes(Object.keys(userRoles).find(key => userRoles[key] === seniorUserType)))
        ) {
            return "A";
        } else if (
            (userTypes.some((userType) => userType.includes(Object.keys(userRoles).find(key => userRoles[key] === medicalUserType))) ||
                userTypes.some((userType) => userType.includes(Object.keys(userRoles).find(key => userRoles[key] === nonMedicalUserType))))
        ) {
            return "J";
        } else {
            return "S"
        }

    };

    getCurrentDateFormat = () => {
        return moment(new Date()).format("MMM DD, YYYY");
    }

    getActiveEnlistmentYear = () => {
        return commonService.getActiveEnlistmentYear().then(
            (response) => {
                return response.data;
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
                // You may choose to throw the error here or handle it in some other way
                throw error;
            }
        );
    };

    // Method to preview file
     previewFile = (file) => {
        const fileType = file.type.split("/")[0];

        if (fileType !== "") {
            const url = URL.createObjectURL(file);
            window.open(url, "_blank");
        }
    };

    graduateCategoryList = () => {
        return commonService.getGraduateCategory().then(
            (response) => {
                return response.data;
            }
            );
    }

    getPreferences = () => {
        return commonService.getPreferences().then(
            (response) => {
                return response.data;
            }
        );
    }
    getTransportModes = () => {
        return commonService.getTransportModes().then(
            (response) => {
                return response.data;
            }
        );
    }

    getFoodCategory = () => {
        return commonService.getFoodCategory().then(
            (response) => {
                return response.data;
            }
        );
    }

    getQualificationCategory = () => {
        return commonService.getQualificationCategory().then(
            (response) => {
                return response.data;
            }
        );
    }

    getSalutation = (gender) => {
        let salutation = "Mr.";
        if (gender === "F") {
            salutation = "Ms.";
        }
        return salutation;
    }

    getCohorts = () => {
        return commonService.getCohorts().then(
            (response) => {
                return response.data;
            }
        );
    }

    geLatestRegistrationDetails = () => {
        return commonService.geLatestRegistrationDetails().then(
            (response) => {
                return response.data;
            }
        );
    }


}
export default new CommonMethod();


