import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import moment from "moment";

import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { changeTopBarTitle } from "../../redux/TopBarTitleSetter";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { tokens } from "../../theme";
import { useFormik } from "formik";
import ButtonType1 from "../Button/ButtonType1";
import nsRegistrationCutOffDateService from "../../services/nsRegistrationCutOffDate.service";
import NsRegistrationCutOffDateActionButton from "./NsRegistrationCutOffDateActionButton";
import activationStatus from "../../data/activationStatus.json";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/lab/Alert";
import { CustomNoRowsOverlay } from "../../Overlay/CustomNoRowOverlay";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import useComponentBasePremission from "../../CustomHooks/useComponetBasePermission";

const NsRegistrationCutOffDate = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [nsRegistrationCutOffDates, setNsRegistrationCutOffDates] = useState(
    []
  );
  const [content, setContent] = useState("");
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });
  const [showAlert, setShowAlert] = useState(false);
  const permissions = useComponentBasePremission("nsRegistrationCutOffDate");

  const { values, setFieldValue, handleSubmit, resetForm, touched, errors } =
    useFormik({
      initialValues: {
        nsRegistrationCutOffDate: null,
        status: null,
      },
      onSubmit: (values) => {
        saveNsRegistrationCutOffDate(values);
      },
      validationSchema: Yup.object().shape({
        nsRegistrationCutOffDate: Yup.date("Invalid date")
          .required()
          .typeError("Invalid date")
          .label("Ns Registration CutOff date"),
        status: Yup.string()
          .required("Status is required")
          .typeError("Status is required"),
      }),
    });

  const [rowId, setRowId] = useState(null);
  const [cellEdited, setCellEdited] = useState(false);
  const [popUpState, setPopUpState] = useState(false);
  const dateFormat = "MMMM DD, YYYY";

  // change top bar title
  useEffect(() => {
    dispatch(changeTopBarTitle("Ns Registration CutOff Date"));
    getNsRegistrationCutOffDate();
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "nsRegistrationCutOffDateId",
        headerName: "SL No",
        flex: 0.2,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        renderCell: (index) =>
          index.api.getRowIndex(index.row.nsRegistrationCutOffDateId) + 1,
      },
      {
        field: "nsRegistrationCutOffDate",
        headerName: "CutOff Date",
        flex: 1,
        headerAlign: "center",
        align: "center",
        editable: permissions[0].edit,
        type: "date",
        headerClassName: "super-app-theme--header",
        valueFormatter: (params) => {
          return moment(params.value).format(dateFormat);
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 0.3,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "singleSelect",
        editable: permissions[0].edit,
        valueOptions: ["A", "I"],
        valueFormatter: (params) => {
          return activationStatus[params.value];
        },
      },
      {
        field: "action",
        headerName: "Action",
        flex: 0.3,
        headerAlign: "center",
        align: "center",
        type: "actions",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          if (permissions[0].edit) {
            return (
              <NsRegistrationCutOffDateActionButton
                {...{ params, rowId, setRowId, cellEdited, setCellEdited }}
              />
            );
          }
        },
      },
    ],
    [rowId, cellEdited]
  );

  const getNsRegistrationCutOffDate = () => {
    nsRegistrationCutOffDateService.getNsRegistrationCutOffDate().then(
      (response) => {
        setNsRegistrationCutOffDates(response.data);
      },
      (error) => {
        setContent(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    );
  };

  function onCellEditCommit(params) {
    setCellEdited(true);
    setRowId(params.id);
  }

  function saveNsRegistrationCutOffDate(data) {
    nsRegistrationCutOffDateService
      .saveNsRegistrationCutOffDate(data)
      .then((res) => {
        setAlertData({
          severity: "success",
          message: "Data saved successfully.",
        });
        setShowAlert(true);
        getNsRegistrationCutOffDate();
        resetForm();
      })
      .catch((error) => {
        setAlertData({
          severity: "error",
          message: error.response.data.message,
        });
        setShowAlert(true);
      });
  }

  return (
    <PageTransitionFadeIn
      child={
        <Box
          boxShadow={5}
          p={{ xs: 2, sm: 5 }}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {/* Add button */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ color: "grey" }}>
              <InfoIcon /> View or Add NS Registration CutOff Date
            </Typography>
            {permissions[0].save && (
              <ButtonType1
                onClick={() => {
                  setPopUpState(true);
                  setShowAlert(false);
                }}
              />
            )}
          </Box>
          {/*/!*Table*!/*/}
          <Box>
            <DataGrid
              density="compact"
              getRowId={(row) => row.nsRegistrationCutOffDateId}
              columns={columns}
              rows={nsRegistrationCutOffDates}
              onCellEditCommit={(params) => onCellEditCommit(params)}
              components={{ NoRowsOverlay: CustomNoRowsOverlay }}
              // rowsPerPageOptions={[5, 10, 20]}

              autoHeight
            />
          </Box>

          {/*Dialog form*/}
          <Dialog
            open={popUpState}
            onClose={() => {
              setPopUpState(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <Typography color="grey" align="center">
                    Add NS Registration CutOff Date
                  </Typography>
                  <DatePicker
                    views={["year", "month", "day"]}
                    label="NS Registration CutOff Date"
                    inputFormat="DD/MM/YYYY"
                    // maxDate={new Date()}
                    // emptyLabel="dd/mm/yyyy"
                    value={values.nsRegistrationCutOffDate}
                    onChange={(selectedDate) => {
                      try {
                        setFieldValue(
                          "nsRegistrationCutOffDate",
                          new Date(selectedDate)
                        );
                      } catch (err) {}
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        helperText={errors.nsRegistrationCutOffDate}
                        error={errors.nsRegistrationCutOffDate}
                      />
                    )}
                  />
                  <TextField
                    value={values.status}
                    required
                    select
                    onChange={(e) => setFieldValue("status", e.target.value)}
                    label="Status"
                    placeholder="Status"
                    size="small"
                    error={"Required" && Boolean(errors.status)}
                    helperText={"Required" && errors.status}
                  >
                    <MenuItem value="A">Active</MenuItem>
                    <MenuItem value="I">Inactive</MenuItem>
                  </TextField>
                </Stack>
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  // close pop up form
                  setPopUpState(false);
                  // clear form
                  resetForm();
                }}
              >
                <Typography
                  color={theme.palette.mode === "dark" ? "white" : "black"}
                >
                  Close
                </Typography>
              </Button>
              <Button onClick={handleSubmit} autoFocus>
                <Typography
                  color={theme.palette.mode === "dark" ? "white" : "black"}
                >
                  Add
                </Typography>
              </Button>
            </DialogActions>
            <Collapse in={showAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity={alertData.severity}
              >
                {alertData.message}
              </Alert>
            </Collapse>
          </Dialog>
        </Box>
      }
    />
  );
};

export default NsRegistrationCutOffDate;
