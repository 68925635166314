import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    MenuItem,
    Snackbar,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {changeTopBarTitle} from "../../redux/TopBarTitleSetter";
import Button from "@mui/material/Button";
import {tokens} from "../../theme";
import {useFormik} from "formik";
import {DesktopDatePicker, LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import InfoIcon from '@mui/icons-material/Info';
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import {CustomNoRowsOverlay} from "../../Overlay/CustomNoRowOverlay";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import useComponentBasePremission from "../../CustomHooks/useComponetBasePermission";
import commonService from "../../services/common.service";
import userListAfterRegistrationService from "../../services/userListAfterRegistration.service";
import UserListAfterRegistrationSchema from "./UserListAfterRegistrationSchema";


const UserListAfterRegistration = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [alertData, setAlertData] = useState({severity: 'info', message: ''});
    const [showAlert, setShowAlert] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [yearList, setYearList] = useState([]);
    const [enlistmentYear, setEnlistmentYear] = useState([]);
    const {values, setFieldValue, handleSubmit, resetForm, errors, touched} = useFormik({
        initialValues: {
            enlistmentYear: '',
            cid: '',
        },
        onSubmit: () => {
            console.log(`on submit: `)
            create(values);
        },
        validationSchema: UserListAfterRegistrationSchema,
    });

    const [userListAfterRegistrationList, setUserListAfterRegistrationList] = useState([]);
    const [content, setContent] = useState('');
    const [rowId, setRowId] = useState(null);
    const [cellEdited, setCellEdited] = useState(false);

    // custom hook to get view, edit, delete, save permissions of provided screen id
    const permissions = useComponentBasePremission("userListAfterRegistration");

    const computeYearList = () => {
        const tmpYr = []
        for (let i = 2024; i <= enlistmentYear.registrationYear; i++) {
            tmpYr.push(i)
        }
        setYearList(tmpYr)
    }
    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };

    useEffect(() => {
        getActiveEnlistmentYear();
    }, []);


    const getActiveEnlistmentYear = () => {
        commonService.getActiveEnlistmentYear().then(
            (response) => {
                setEnlistmentYear(response.data);
            },
            (error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        );
    };

    const columns = [
        {
            field: 'id',
            headerName: 'SL No',
            flex: 0.3,
            headerAlign: "center",
            align: "center",
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        },
        {
            field: 'enlistmentYear',
            headerName: 'Year',
            flex: 0.6,
            headerAlign: "center",
            align: "center",
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'cid',
            headerName: 'CID',
            flex: 0.6,
            headerAlign: "center",
            align: "center",
            headerClassName: 'super-app-theme--header',

        },
    ]

    const [popUpState, setPopUpState] = useState(false);

    const getUserListAfterRegistrationList = () => {
        userListAfterRegistrationService.getAll().then(
            response => {
                console.log(response.data)
                setUserListAfterRegistrationList(response.data)
            },
            error => {
                console.dir(error)
                setContent(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                );
            }
        )
    }


    function create(fields) {
        userListAfterRegistrationService.save(fields)
            .then((res) => {
                if (res.status !== 208) {
                    setAlertData({severity: 'success', message: "User added successfully."})
                    setShowAlert(true)
                    getUserListAfterRegistrationList();
                    resetForm();

                } else {
                    setAlertData({severity: 'warning', message: res.data})
                    setShowAlert(true)
                }


            })
            .catch(() => {
                setAlertData({severity: 'error', message: 'Error: Failed to add user.'})
                setShowAlert(true);
            });
    }

    function onCellEditCommit(params) {
        setCellEdited(true);
        setRowId(params.id)
    }

    // run one time at the beginning
    useEffect(() => {
        computeYearList()
        // change top bar title
        dispatch(changeTopBarTitle('User who have not registered'))
        getUserListAfterRegistrationList();
    }, []);

    // run one time at the beginning
    useEffect(() => {
        if(enlistmentYear!==null){
            computeYearList()
        }
    }, [enlistmentYear]);


    return <PageTransitionFadeIn child={
        <Box boxShadow={5} p={{xs: 2, sm: 5}} display="flex" flexDirection="column" gap={2}>
            {/* Add button */}
            <Box sx={{display: "flex", justifyContent: "space-between"}} gap={1}
                 flexDirection={{xs: "column", sm: "row"}}>
                <Typography sx={{color: "grey"}}><InfoIcon/> View user list who have not registered</Typography>
                {permissions[0].save && <Button
                    size="small"
                    variant="outlined"
                    sx={{
                        boxShadow: "1",
                        color: theme.palette.mode !== "dark" ? colors.main[900] : "white",
                        borderColor: theme.palette.mode !== "dark" ? colors.main[900] : "white",
                        "&:hover": {
                            borderColor: theme.palette.mode !== "dark" ? colors.main[900] : "white",
                            backgroundColor: theme.palette.mode !== "dark" ? colors.main[900] : "white",
                            color: theme.palette.mode !== "dark" ? "white" : colors.primary[400],
                        }
                    }}
                    onClick={() => {
                        setPopUpState(true)
                    }}
                >
                    Add
                </Button>
                }
            </Box>
            {/*/!*Table*!/*/}
            <Box>
                <DataGrid
                    density="compact"
                    columns={columns}
                    components={{
                        Toolbar: GridToolbar,
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={userListAfterRegistrationList}
                    onCellEditCommit={(params) => onCellEditCommit(params)}
                    autoHeight
                />
                <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleCloseSuccess}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                          }}>
                    <Alert severity="warning">{warningMessage}</Alert>
                </Snackbar>
            </Box>
            {/*Pop-up form*/}
            <Dialog
                open={popUpState}
                onClose={() => {
                    setPopUpState(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <Typography color="grey" align="center">Add user who have not registered</Typography>
                            <TextField
                                required
                                select
                                label="Year" placeholder="Year"
                                size="small"
                                defaultValue=''
                                value={values.enlistmentYear}
                                onChange={(e) => setFieldValue('enlistmentYear', e.target.value)}
                                error={touched.enlistmentYear && errors.enlistmentYear}
                                helperText={'Required' && errors.enlistmentYear}
                            >
                                {
                                    yearList.map((item) => (
                                        <MenuItem key={item} value={item.toString()}>{item}</MenuItem>
                                    ))
                                }
                            </TextField>
                            <TextField
                                sx={{ width: "60vh" }}
                                size="small"
                                placeholder="CID"
                                label="CID"
                                required
                                value={values.cid}
                                error={touched.cid && errors.cid}
                                helperText={'Required' && errors.cid}
                                onChange={(event) =>
                                    setFieldValue("cid", event.target.value)
                                }
                            />
                        </Stack>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        // close pop up form
                        setPopUpState(false);
                        setShowAlert(false);
                        // clear form
                        resetForm();
                    }}>Cancel</Button>
                    <Button autoFocus onClick={handleSubmit}>Add</Button>
                </DialogActions>
                <Collapse in={showAlert}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowAlert(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                        severity={alertData.severity}
                    >{alertData.message}</Alert>
                </Collapse>
            </Dialog>
        </Box>
    }/>
}

export default UserListAfterRegistration;