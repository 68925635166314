import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  Slide,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CachedIcon from "@mui/icons-material/Cached";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { changeTopBarTitle } from "../../redux/TopBarTitleSetter";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import apiClientService from "../../services/apiClient.service";
import { CustomNoRowsOverlay } from "../../Overlay/CustomNoRowOverlay";
import PageTransitionFadeIn from "../animation/PageAnimation/PageAnimation";
import useComponentBasePremission from "../../CustomHooks/useComponetBasePermission";
import * as Yup from "yup";
import SaveIcon from "@mui/icons-material/SaveAs";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import activationStatus from "../../data/activationStatus.json";
import ApiClientActionButton from "./ApiClientActionButton";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const ApiClient = () => {
  const dateFormat = "MMMM DD, YYYY";
  const dispatch = useDispatch();
  const [rowId, setRowId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [cellEdited, setCellEdited] = useState(false);
  const [progress, setProgress] = useState(false);
  const [alertData, setAlertData] = useState({
    show: false,
    severity: "info",
    message: "",
  });
  const [confirmStatus, setConfirmStatus] = useState(false);

  const [popUpState, setPopUpState] = useState(false);
  const [allApiClient, setAllApiClient] = useState([]);
  const permissions = useComponentBasePremission("apiClient");
  const { values, setFieldValue, handleSubmit, resetForm, touched, errors } =
    useFormik({
      initialValues: {
        clientKey: "",
        clientSecret: "",
        status: "A",
        remarks: "",
      },
      onSubmit: (values) => {
        addApiClient(values);
      },
      validationSchema: Yup.object().shape({
        clientKey: Yup.string().required("This field is required"),
        clientSecret: Yup.string().required("This field is required"),
        status: Yup.string().required("This field is required"),
      }),
    });

  // change top bar title
  useEffect(() => {
    dispatch(changeTopBarTitle("API Clients"));
    getAllApiClient();
  }, []);

  const getAllApiClient = () => {
    apiClientService.getAllApiClient().then(
      (response) => {
        setAllApiClient(response.data);
      },
      (error) => {}
    );
  };

  const generateKeyAndSecret = () => {
    setAlertData({ ...alertData, show: false });
    const length = 30;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let clientKey = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      clientKey += characters.charAt(randomIndex);
    }

    let clientSecret = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      clientSecret += characters.charAt(randomIndex);
    }
    setFieldValue("clientKey", clientKey);
    setFieldValue("clientSecret", clientSecret);
  };

  const addApiClient = () => {
    setProgress(true);

    setAlertData({ ...alertData, show: false });
    let clientKey = values.clientKey;
    let clientSecret = values.clientSecret;
    let remarks = values.remarks;
    let status = values.status;

    const data = {
      clientKey,
      clientSecret,
      remarks,
      status,
    };

    apiClientService.saveApiClient(data).then(
      (response) => {
        setProgress(false);
        setAlertData({
          show: true,
          severity: "success",
          message: response.data.message,
        });
        resetForm();
        getAllApiClient();
      },
      (error) => {
        setProgress(false);
        setAlertData({
          show: true,
          severity: "error",
          message: error.response.data.message,
        });
      }
    );
  };

  const handleDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setPopUpState(false);
  };

  const handleDeleteDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setConfirmStatus(false);
  };

  async function deleteApiClient() {
    setCellEdited(null);
    setProgress(true);
    await apiClientService
      .deleteApiClient(clientId)
      .then(() => {
        setConfirmStatus(false);
        getAllApiClient();
      })
      .catch((error) => {});
    setProgress(false);
    setRowId(null);
  }

  const columns = [
    {
      field: "clientId",
      headerName: "SL No",
      flex: 0.3,
      headerClassName: "super-app-theme--header",
      renderCell: (index) =>
        (
          index.api.getRowIndexRelativeToVisibleRows(index.row.clientId) + 1
        ).toString(),
    },
    {
      field: "status",
      editable: permissions[0].edit,
      type: "singleSelect",
      valueOptions: ["A", "I"],
      headerName: "Status",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        return activationStatus[params.value];
      },
      renderCell: (params) => {
        return (
          <>
            <FiberManualRecordIcon
              style={{
                fontSize: 14,
                color: params.value === "A" ? "#07ab03" : "#9e9e9e",
                marginRight: 2,
              }}
            />
            {activationStatus[params.value]}
          </>
        );
      },
    },
    {
      field: "clientKey",
      headerName: "Client Key",
      flex: 1.3,
      editable: permissions[0].edit,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "clientSecretPlain",
      headerName: "Client Secret",
      flex: 1.3,
      editable: permissions[0].edit,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      flex: 0.8,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        return moment(params.value).format(dateFormat);
      },
    },
    {
      field: "remarks",
      editable: permissions[0].edit,
      headerName: "Remarks",
      flex: 1.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {permissions[0].edit && (
              <ApiClientActionButton
                {...{ params, rowId, setRowId, cellEdited, setCellEdited }}
              />
            )}
            {permissions[0].delete && (
              <DeleteForeverOutlinedIcon
                style={{ color: "#f63e3e", cursor: "pointer" }}
                onClick={() => {
                  setConfirmStatus(true);
                  setClientId(params.row.clientId);
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  function onCellEditCommit(params) {
    setCellEdited(true);
    setRowId(params.id);
  }
  return (
    <PageTransitionFadeIn
      child={
        <Box
          boxShadow={3}
          p={{ xs: 2, sm: 2 }}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {/* Add button */}
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            gap={1}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Typography sx={{ color: "grey" }}>
              <InfoIcon /> API Clients{" "}
              <span style={{ color: "#f63e3e" }}>
                (The API clients are third-party systems that seek access to the
                API provided by this system).
              </span>
            </Typography>
            {permissions[0].save && (
              <Button
                style={{ textTransform: "initial" }}
                variant="outlined"
                color="info"
                startIcon={<AddIcon />}
                onClick={() => {
                  setPopUpState(true);
                  setAlertData({ ...alertData, show: false });
                }}
              >
                <Typography>Add</Typography>
              </Button>
            )}
          </Box>

          <Box className="data-grid-container">
            <DataGrid
              density="standard"
              columns={columns}
              rows={allApiClient}
              autoHeight
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              getRowId={(row) => row.clientId}
              onCellEditCommit={(params) => onCellEditCommit(params)}
            />
          </Box>
          {/*Dialog form*/}
          <Dialog
            disableEscapeKeyDown={true}
            fullWidth
            open={popUpState}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Typography color="grey" align="center" variant="h6">
                <strong>Add API Client</strong>
              </Typography>
              <Stack spacing={2} sx={{ display: "flex" }} padding={2}>
                <TextField
                  size="small"
                  label="Client Key"
                  placeholder="Client Key"
                  multiline
                  fullWidth
                  required
                  disabled={progress}
                  value={values.clientKey}
                  inputProps={{ maxLength: 30 }}
                  onChange={(event) =>
                    setFieldValue("clientKey", event.target.value)
                  }
                  error={touched.clientKey && errors.clientKey}
                  helperText={"Required" && errors.clientKey}
                />

                <TextField
                  size="small"
                  label="Client Secret"
                  placeholder="Client Secret"
                  multiline
                  fullWidth
                  required
                  disabled={progress}
                  value={values.clientSecret}
                  inputProps={{ maxLength: 30 }}
                  onChange={(event) =>
                    setFieldValue("clientSecret", event.target.value)
                  }
                  error={touched.clientSecret && errors.clientSecret}
                  helperText={"Required" && errors.clientSecret}
                />

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    endIcon={<CachedIcon />}
                    style={{ textTransform: "initial" }}
                    variant="outlined"
                    size="small"
                    color="success"
                    disabled={progress}
                    onClick={() => {
                      generateKeyAndSecret();
                    }}
                  >
                    <Typography>Generate KEY and SECRET</Typography>
                  </Button>
                </Box>
                <Box>
                  <TextareaAutosize
                    disabled={progress}
                    placeholder="Remarks"
                    rows={2}
                    maxLength={100}
                    style={{ width: "100%" }}
                    value={values.remarks}
                    onChange={(event) =>
                      setFieldValue("remarks", event.target.value)
                    }
                  />
                </Box>

                <TextField
                  value={values.status}
                  required
                  select
                  disabled={progress}
                  onChange={(e) => setFieldValue("status", e.target.value)}
                  label="Status"
                  size="small"
                  error={touched.status && errors.status}
                  helperText={"Required" && errors.status}
                >
                  <MenuItem value="A">Active</MenuItem>
                  <MenuItem value="I">Inactive</MenuItem>
                </TextField>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                style={{ textTransform: "initial" }}
                variant="outlined"
                color="warning"
                disabled={progress}
                onClick={() => {
                  setPopUpState(false);
                  resetForm();
                }}
              >
                <Typography>Close</Typography>
              </Button>
              {permissions[0].save && (
                <Button
                  startIcon={<SaveIcon />}
                  style={{ textTransform: "initial" }}
                  variant="outlined"
                  disabled={progress}
                  onClick={handleSubmit}
                >
                  <Typography>Save</Typography>
                  {progress && (
                    <CircularProgress
                      size={30}
                      sx={{
                        position: "absolute",
                        p: 1,
                      }}
                    />
                  )}
                </Button>
              )}
            </DialogActions>
            <Box padding={2}>
              <Collapse in={alertData.show}>
                <Alert
                  variant="outlined"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertData({ ...alertData, show: false });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity={alertData.severity}
                >
                  <Typography>{alertData.message}</Typography>
                </Alert>
              </Collapse>
            </Box>
          </Dialog>

          {/* delete dialog */}
          <Dialog
            TransitionComponent={Transition}
            open={confirmStatus}
            disableEscapeKeyDown={true}
            onClose={handleDeleteDialogClose}
            aria-labelledby="alert-dialog-title"
            sx={{ borderTop: "5px solid red" }}
          >
            <DialogContent sx={{ borderTop: "6px solid #2f7d31" }}>
              <>
                <Stack spacing={1}>
                  <Typography textAlign="center" variant="h3">
                    <HelpIcon
                      sx={{
                        fontSize: "30px",
                        color: "#ed6d03",
                      }}
                    />
                    Confirmation
                  </Typography>
                  <Typography align="justify" variant="h6">
                    Are you sure you want to delete permanently?
                  </Typography>
                  <Typography align="center">
                    Once deleted, it cannot be undone.
                  </Typography>
                </Stack>
              </>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                style={{ textTransform: "initial" }}
                variant="outlined"
                color="warning"
                disabled={progress}
                onClick={() => {
                  setConfirmStatus(false);
                }}
              >
                <Typography>No, Cancel</Typography>
              </Button>

              {permissions[0].delete && (
                <Button
                  style={{ textTransform: "initial" }}
                  disabled={progress}
                  variant="outlined"
                  color="success"
                  onClick={() => deleteApiClient()}
                >
                  <Typography>Yes, Sure</Typography>
                  {progress && (
                    <CircularProgress
                      size={30}
                      sx={{
                        position: "absolute",
                        p: 1,
                      }}
                    />
                  )}
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      }
    />
  );
};

export default ApiClient;
