import axios from "axios";
import multipartAuthHeader from "./auth-header-multipart";
import authHeader from "./auth-header";

const BASE_URL =
    process.env.REACT_APP_BASE_URL +
    "api/enrolment/deferment/exemption/specialexemption";

const save = (formData) => {
    return axios.post(
        BASE_URL,
        formData,
        // return axios.post("api/enrolment/deferment/exemption/exemption", formData,
        {
            headers: multipartAuthHeader(),
        }
    );
};

const getSpecExemptionListByCriteria = (userRole,status, gender,cid,mailStatus,specialExemptionYear,dobYear,offset,pageSize,reasonId) => {
    return axios.get(BASE_URL + "/getSpecExemptionListByCriteria",{params: {userRole,status,gender,cid,mailStatus,dobYear,specialExemptionYear,offset,pageSize,reasonId},headers: authHeader(),});
};

const getExemptionFileListByCommonExemptionFileId = (commonExemptionFileId) => {
    return axios.get(
        BASE_URL + "/getExemptionFileListByCommonExemptionFileId",
        {params: {commonExemptionFileId}, headers: authHeader()}
    );
};

const getSpecialExemptionAuditListBySpecExemptionId = (specExemptionId) => {
    return axios.get(
        BASE_URL + "/getSpecialExemptionAuditListBySpecExemptionId",
        {params: {specExemptionId}, headers: authHeader()}
    );
};

const saveBulkSpecialExemption = (data) => {
    return axios.post(
        BASE_URL + "/saveBulkSpecialExemption",
        data,
        { headers: multipartAuthHeader() }
    );
};

const checkApprovedExemptionByCid = (cid) => {
    return axios.get(
        BASE_URL + "/checkApprovedExemptionByCid",
        {params: {cid}, headers: authHeader()}
    );
};

const reviewById = (specialExemptionId, reviewRemarks, userId, status, userRole, emailContent) => {
    return axios.post(BASE_URL + "/reviewById",
        {
            userId,
            reviewRemarks,
            status,
            specialExemptionId,
            userRole,
            emailContent
        },
        {
            headers: authHeader()
        },
    );
};

const saveSpecialExemptionRemarkFiles = (formData) => {
    return axios.post(BASE_URL + "/upsertRemarksSpecialExemption", formData, {
        headers: multipartAuthHeader(),
    });
};

const getSpecExemptionRemarksFileListByAuditId = (specExemptionAuditId) => {
    return axios.get(BASE_URL + "/getSpecExemptionRemarksFileListByAuditId", {
        params: { specExemptionAuditId },
        headers: authHeader(),
    });
};
const approvalBulkSpecialExemption = (data) => {
    return axios.post(
        BASE_URL + "/approvalBulkSpecialExemption",
        data,
        { headers: authHeader() }
    );
};

const approvalById = (specialExemptionId, reviewRemarks, userId, status, userRole, emailContent) => {
    return axios.post(BASE_URL + "/approvalById",
        {
            userId,
            reviewRemarks,
            status,
            specialExemptionId,
            userRole,
            emailContent
        },
        {
            headers: authHeader()
        },
    );
};

const mailSendToApplicant = (specialExemptionId, reviewRemarks, userId, status, userRole, emailContent) => {
    return axios.post(BASE_URL + "/mailSendToApplicant",
        {
            specialExemptionId,
            reviewRemarks,
            userId,
            status,
            userRole,
            emailContent
        },
        {
            headers: authHeader()
        },
    );
};

const getEmailContentBySpecialExemptionAuditId = (specialExemptionAuditId) => {
    return axios.get(
        BASE_URL + "/getEmailContentBySpecialExemptionAuditId",
        { params: { specialExemptionAuditId }, headers: authHeader() }
    );
};

const getSpecialExemptionByCid = (cid) => {
    return axios.get(
        BASE_URL + "/getSpecialExemptionByCid",
        {params: {cid}, headers: authHeader()}
    );
};

const mailSendBulkToApplicant = (data) => {
    return axios.post(
        BASE_URL + "/mailSendBulkToApplicant",
        data,
        { headers: authHeader() }
    );
};

const getSpecialExemptionListByCid = (cid) => {
    return axios.get(BASE_URL + "/getSpecialExemptionListByCid",
        // return axios.get("api/enrolment/deferment/exemption/deferment" + "/getDefermentListByDefermentYearReasonStatus",
        {params: {cid}, headers: authHeader()});
}

export default {
    save,
    getSpecExemptionListByCriteria,
    getExemptionFileListByCommonExemptionFileId,
    getSpecialExemptionAuditListBySpecExemptionId,
    saveBulkSpecialExemption,
    checkApprovedExemptionByCid,
    reviewById,
    saveSpecialExemptionRemarkFiles,
    getSpecExemptionRemarksFileListByAuditId,
    approvalBulkSpecialExemption,
    approvalById,
    mailSendToApplicant,
    getEmailContentBySpecialExemptionAuditId,
    getSpecialExemptionByCid,
    mailSendBulkToApplicant,
    getSpecialExemptionListByCid
};