import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class ApiClientService {
  getAllApiClient() {
    return axios.get(BASE_URL + "api/auth/apiClient/getAllApiClient", {
      headers: authHeader(),
    });
  }

  saveApiClient(data) {
    return axios.post(BASE_URL + "api/auth/apiClient/saveApiClient", data, {
      headers: authHeader(),
    });
  }

  updateApiClient(data) {
    return axios.post(BASE_URL + "api/auth/apiClient/updateApiClient", data, {
      headers: authHeader(),
    });
  }

  deleteApiClient(clientId) {
    return axios.delete(BASE_URL + "api/auth/apiClient/deleteApiClient", {
      params: { clientId },
      headers: authHeader(),
    });
  }
}

export default new ApiClientService();
