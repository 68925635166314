import { Box, Toolbar, Typography } from "@mui/material";
import pageNotFoundSvg from "../../assets/page-not-found.svg";
import pageNotFoundImg from "../../assets/404.png";

const PageNotFound = () => {
  return (
    <Box
      gap={3}
      display="flex"
      flexDirection="column"
      height="70%"
      alignItems="center"
    >
      {/* <Toolbar /> */}
      <img
        className="img-fluid"
        // src={pageNotFoundSvg}
        src={pageNotFoundImg}
        alt="Page not found"
        width="50%"
      />
      <Typography
        align="center"
        variant="h3"
        color="text.secondary"
        textAlign="justify"
      >
        Sorry, we can't find that page!
      </Typography>
      <Typography
        align="center"
        variant="h5"
        color="text.secondary"
        textAlign="justify"
      >
        The page you are looking for was moved, removed, renamed or never
        existed.
      </Typography>
    </Box>
  );
};

export default PageNotFound;
