import {LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SET_MESSAGE,} from "./types";

import AuthService from "../services/auth.services";
import {sideMenuChildrenSetter} from "../redux/sideMenuItemSetter";

export const register = (data) => (dispatch) => {
    return AuthService.register(data);
};

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
        (data) => {
            dispatch(sideMenuChildrenSetter(data.accessPermissions))
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {user: data},
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};
