import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import store from "./redux/store";
import {Provider} from "react-redux";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
            <Provider store={store}>
                <App/>
            </Provider>
        </DevSupport>
    // </React.StrictMode>
);
