import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import { ColorModeContext, tokens } from "../../theme";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import store from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pathGenerator } from "../../helper/PathGenerator";
import { logout } from "../../actions/auth";
import profileService from "../../services/profile.service";
import { motion } from "framer-motion";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from "@mui/icons-material/Menu";
import Fade from "@mui/material/Fade";
import HomeIcon from "@mui/icons-material/Home";
import logo from "../../assets/logo/logo-gyalsung.png";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import AuthService from "../../services/auth.services";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BallotIcon from "@mui/icons-material/Ballot";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import GroupIcon from "@mui/icons-material/Group";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import QuizIcon from "@mui/icons-material/Quiz";
import LockResetIcon from "@mui/icons-material/LockReset";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import StartIcon from "@mui/icons-material/Start";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import SubjectIcon from "@mui/icons-material/Subject";
import Collapse from "@mui/material/Collapse";
import RemoveIcon from "@mui/icons-material/Remove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import * as React from "react";
import nsRegistrationService from "../../services/ns.registration.service";
import { useTranslation, i18next } from "react-i18next";

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "none",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));

const TopBarStudent = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(true);

  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { user: currentUser } = useSelector((state) => state.auth);
  const siginUser = JSON.parse(localStorage.getItem("user"));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showTopBarTitle, setShowTopBarTitle] = useState(false);
  const [registered, setRegistered] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    getProfileInfo();
    // ---------------------------------
    const u = JSON.parse(localStorage.getItem("user"));
    let tmpSideMenuItem = u.accessScreens;
    const data = {};
    let fData = [];
    const tmpIsCollapse = {};
    const tL = [];

    // nsRegistrationService.checkUserAlreadyRegistered(u.userId).then((response) => {
    //   if(response.data){
    //     setRegistered(true);
    //   }
    // });
  }, []);

  useEffect(() => {
    setShowTopBarTitle(false);

    setTimeout(() => {
      setShowTopBarTitle(true);
    }, 300);
  }, [store.getState().topBarTitleSetter.title]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();

  function onLangChange(event) {
    setLang(event.target.checked);
    if (event.target.checked) {
      i18n.changeLanguage("dz");
      localStorage.setItem("language", "DZO");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "ENG");
    }
  }
  const handleLogOut = useCallback(() => {
    AuthService.logout();
    navigate("/");
  }, [dispatch]);

  const [mobileOpen, setMobileOpen] = useState(false);

  useSelector((state) => state.topBarTitleSetter);

  const navigate = useNavigate();

  const drawerWidth = 240;

  const getProfileInfo = () => {
    if (siginUser !== null) {
      profileService.getProfileInfo(siginUser.userId).then(
        (response) => {
          // setDisplayName(response.data.fullName);
          setUserInfo(response.data);
        },
        (error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
      );
    }
  };

  const drawer = (
    <Box>
      <Toolbar
        sx={{
          // backgroundColor: "white",
          // position: "fixed",
          // boxShadow: 1,
          // zIndex: 100,
          // width: `${drawerWidth}px`,
          background: "linear-gradient(to right, rgb(4 4 4), rgb(4 4 4))",
        }}
      >
        {/* <Typography variant="h6">Side Menu m</Typography> */}
        <img
          src={logo}
          alt="logo"
          width={"70%"}
          // height={"60%"}
          // style={{ maxWidth: "100%", paddingBottom: "75%" }}
        />
      </Toolbar>
      <Divider />
      {/* <List
        sx={{
          height: "100vh",
          overflow: "auto",
        }}
        dense
      >
        Mobile view
      </List> */}

      <Box>
        <Box padding={2} sx={{ display: "flex", justifyContent: "center" }}>
          {/* <Avatar
            sx={{
              width: 150,
              height: 150,
              border: "0.2px solid white",
              cursor: "pointer",
            }}
            src={`data:image/jpeg;base64,${profilePhoto}`}
          /> */}
        </Box>

        <Typography align="center" variant="h6">
          Welcome
        </Typography>
        <Typography
          align="center"
          variant="h6"
          sx={{ fontWeight: "bold", color: "gray" }}
        >
          {userInfo.fullName}
        </Typography>
        <Box padding={3}>
          <List component="nav" aria-label="main mailbox folders">
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={() => {
                navigate("/authenticatedViewWrapper/openUserDashboard");
                setSelectedIndex(0);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={t("Home")} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 1}
              onClick={() => {
                navigate("/authenticatedViewWrapper/nsRegistration");
                setSelectedIndex(1);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary={t("NS Registration")} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 2}
              onClick={() => {
                navigate("/authenticatedViewWrapper/myEarlyEnlistmentApplications");
                setSelectedIndex(2);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <GroupAddIcon />
              </ListItemIcon>
              <ListItemText primary={t("Early Enlistment")} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 3}
              onClick={() => {
                navigate("/authenticatedViewWrapper/myDefermentApplications");
                setSelectedIndex(3);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <EventRepeatIcon />
              </ListItemIcon>
              <ListItemText primary={t("Deferment")} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 4}
              onClick={() => {
                navigate("/authenticatedViewWrapper/applyExemption");
                setSelectedIndex(4);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <PersonAddDisabledIcon />
              </ListItemIcon>
              <ListItemText primary={t("Exemption")} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 5}
              onClick={() => {
                navigate("/authenticatedViewWrapper/myMedicalDashboard");
                setSelectedIndex(5);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <LocalHospitalIcon />
              </ListItemIcon>
              <ListItemText primary={t("Medical Screening")} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 6}
              onClick={() => {
                navigate("/authenticatedViewWrapper/gyalsupProfile");
                setSelectedIndex(6);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={t("My Profile")} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 7}
              onClick={() => {
                navigate("/authenticatedViewWrapper/settingDashboard");
                setSelectedIndex(7);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary={t("Setting")} />
            </ListItemButton>
            {/* <Box padding={4}>
              <Typography>
                English
                <Switch
                  checked={lang}
                  onChange={onLangChange}
                  color="secondary"
                />
                རྫོང་ཁ།
              </Typography>
            </Box> */}
          </List>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  return (
    <Box>
      <motion.div
        initial={{ y: "-10vh" }}
        animate={{ y: "0vh" }}
        transition={{ duration: 0.8 }}
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 3,
        }}
      >
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            background: colors.main[900],
          }}
        >
          <Toolbar sx={{ display: "flex" }}>
            {/*pop up menu*/}

            {/* drawer button*/}
            <ListIcon
              sx={{ display: { xs: "block", sm: "none" } }}
              onClick={handleDrawerToggle}
            />
            {/*Top Bar Title*/}
            <Fade in={showTopBarTitle}>
              <Typography
                noWrap
                px={3}
                color="black" //{theme.palette.mode==="dark"?"black":"black"}
                variant={"h4"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/authenticatedViewWrapper/openUserDashboard");
                  setSelectedIndex(0);
                  setMobileOpen(false);
                }}
              >
                <HomeIcon
                  sx={{
                    display: { xs: "block", sm: "none" },
                    marginRight: "8px",
                  }}
                />
                <Typography variant={"h4"}>{t("Gyalsung Portal")}</Typography>
              </Typography>
            </Fade>
            {/*user avatar*/}
            <Stack
              direction="row"
              flex={1}
              justifyContent="right"
              justifyItems="right"
              color="black"
            >
              <Icons>
                <Typography>{userInfo.fullName}</Typography>

                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleLogOut}
                >
                  {t("Logout")} <LogoutIcon />
                </Typography>
              </Icons>
              <UserBox>
                {/* <Avatar
                  sx={{ width: 50, height: 50 }}
                  src={`data:image/jpeg;base64,${profilePhoto}`}
                  onClick={(e) => setOpenMenu(true)}
                /> */}

                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleLogOut}
                >
                  {t("Logout")} <LogoutIcon />
                </Typography>
              </UserBox>
            </Stack>
          </Toolbar>
        </AppBar>
        {/*drawer for mobile*/}
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            // shows when screen is extra-small
            display: { xs: "block", sm: "none" },
            // scroll bar style
            "*::-webkit-scrollbar": {
              width: "3px",
            },
            "*::-webkit-scrollbar-track": {
              background: "#E4EFEF",
            },
            "*::-webkit-scrollbar-thumb": {
              background:
                theme.palette.mode === "dark" ? "#1D388F61" : "#fbd388",
            },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {/* mobile phone*/}
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? colors.primary[400]
                    : undefined,
              },
            }}
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </motion.div>
    </Box>
  );
};

export default TopBarStudent;
