import { jwtDecode } from "jwt-decode";
import authServices from "./auth.services";

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    return {
      Authorization: "Bearer " + user.accessToken,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}

// export default function authHeader() {
//   try {
//     let user = JSON.parse(localStorage.getItem("user"));
//     if (user && user.accessToken && !isTokenExpired(user.accessToken)) {
//       return {
//         Authorization: "Bearer " + user.accessToken,
//         "Content-Type": "application/json",
//       };
//     } else {
//       const newAccessToken = regenerateToken();
//       user.accessToken = newAccessToken;
//       localStorage.setItem("user", JSON.stringify(user));
//       return {
//         Authorization: "Bearer " + newAccessToken,
//         "Content-Type": "application/json",
//       };
//     }
//   } catch (error) {
//     throw error;
//   }
// }

const isTokenExpired = (accessToken) => {
  try {
    const decodedToken = jwtDecode(accessToken);
    const expiryTime = decodedToken.exp;
    const currentTime = Date.now();
    const isExpired = expiryTime <= currentTime;
    return isExpired;
  } catch (error) {
    return true;
  }
};

const regenerateToken = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.refreshToken) {
      const refreshToken = user.refreshToken;
      const data = { refreshToken };
      const response = await authServices.regenerateToken(data);

      if (response.data.accessToken) {
        const updatedUser = {
          ...user,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        return response.data.accessToken;
      }
    }
  } catch (error) {
    console.error("session expired. Please login again");
    //todo: add to redux sessionExpired
    throw error;
  }
};
