import { Box, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

const SignUpVerification = ({
  personalData,
  contactDetail,
  onDeclarationChecked,
  contactDetailFormik,
  isChecked,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Stack spacing={0.5}>
        <table>
          <tbody>
            <tr>
              <td>{t("Name")}:</td>
              <td>
                <strong>{personalData.fullName}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("CID")}:</td>
              <td>
                <strong>{personalData.cid}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("DOB")}:</td>
              <td>
                <strong>{personalData.dob}</strong>
              </td>
            </tr>
            {contactDetailFormik.values.mobileNoVerified ? (
              <tr>
                <td>{t("Mobile Number")}:</td>
                <td>
                  <strong>+{contactDetailFormik.values.mobileNo}</strong>
                </td>
              </tr>
            ) : (
              <></>
            )}
            {contactDetailFormik.values.emailVerified ? (
              <tr>
                <td>{t("Email")}:</td>
                <td>
                  <strong>{contactDetailFormik.values.email}</strong>
                </td>
              </tr>
            ) : (
              <></>
            )}

            <tr>
              <td>{t("Gender")}:</td>
              <td>
                <strong>
                  {personalData.gender === "M" ? "Male" : "Female"}
                </strong>
              </td>
            </tr>
            <tr>
              <td>{t("Father's Name")}:</td>
              <td>
                <strong>{personalData.fatherName}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("Father's CID")}:</td>
              <td>
                <strong>{personalData.fatherCid}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("Mother's Name")}: </td>
              <td>
                <strong>{personalData.motherName}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("Mother's CID")}: </td>
              <td>
                <strong>{personalData.motherCid}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("Village")}: </td>
              <td>
                <strong>{personalData.villageName}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("Geog")}: </td>
              <td>
                <strong>{personalData.geogName}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("Dzongkhag")}: </td>
              <td>
                <strong>{personalData.dzongkhagName}</strong>
              </td>
            </tr>
            <tr>
              <td>{t("Present Country")}: </td>
              <td>
                <strong>{contactDetailFormik.values.presentCountry}</strong>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2">
                <strong>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => onDeclarationChecked(e.target.checked)}
                  />
                  {t(
                    "I declare that the information provided above is accurate and true to the best of my knowledge."
                  )}
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </Stack>
    </Box>
  );
};

export default SignUpVerification;
