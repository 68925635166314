import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const proofNdiRequest = () => {
    return (
        axios
            .post(BASE_URL + "api/auth/ndi/proofRequest", [])
            .then((response) => {
                // console.log(response);
                return response;
            })
    );
};
const subscribe = (threadId) => {
    return axios
        .get(BASE_URL + "api/user/profile/signup/subscribe", {
            params: { threadId },
        })
        .then((response) => {
            return response;
        });
};
const proofNdiRequestSignUp = () => {
    return (
        axios
            .post(BASE_URL + "api/user/profile/signup/proofRequest", [])
            .then((response) => {
                // console.log(response);
                return response;
            })
    );
};

export default {
    proofNdiRequest,
    subscribe,
    proofNdiRequestSignUp
};