import React, { useState } from "react";
import { Typography } from "@mui/material";

const CustomTooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: "relative",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            zIndex: 9999,
          }}
        >
          <Typography variant="body2">{text}</Typography>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
