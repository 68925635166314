import Box from "@mui/material/Box";
import ButtonCard from "./ComponentFragment/ButtonCard";
import * as React from "react";
import { Toolbar, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import TopBar from "../TopBar/TopBar";
import WebsiteHeader from "../Home/WebsiteHeader";
import StudentDashboard from "./ComponentFragment/StudentDashboard";

const OpenUserDashboard = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box height={"100vh"} width={"100%"}>
      {/* <WebsiteHeader showSignInSignOut={false} initialY={"-10vh"} animateY={"0vh"}/> */}
      {/* <Toolbar /> */}
      {/* <ButtonCard /> */}
      <StudentDashboard />
    </Box>
  );
};

export default OpenUserDashboard;
