import { Box, CircularProgress, IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useState } from "react";
import earlyEnlistmentCutOffDateService from "../../services/earlyEnlistmentCutOffDate.service";

const EarlyEnlistmentCutOffDateActionButton = ({
  params,
  rowId,
  setRowId,
  cellEdited,
  setCellEdited,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function updateEarlyEnlistmentCutOffDate(row) {
    setCellEdited(null);
    setLoading(true);
    const fields = {
      earlyEnlistmentCutOffDateId: row.earlyEnlistmentCutOffDateId,
      earlyEnlistmentCutOffDate: row.earlyEnlistmentCutOffDate,
      status: row.status,
    };
    await earlyEnlistmentCutOffDateService
      .updateEarlyEnlistmentCutOffDate(fields)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {});
    setLoading(false);
    setRowId(null);
  }

  return (
    <Box key={params.id} sx={{ position: "relative" }}>
      {success && !cellEdited ? (
        <IconButton
          color="primary"
          sx={{
            width: 40,
            height: 40,
            backgroundColor: "green[500]",
            "&:hover": { backgroundColor: "green[700]" },
          }}
          onClick={() => setSuccess(false)} // helps to show back the save icon
        >
          <CheckCircleOutlineIcon />
        </IconButton>
      ) : (
        <IconButton
          color="primary"
          disabled={params.id !== rowId || loading}
          onClick={() => updateEarlyEnlistmentCutOffDate(params.row)}
        >
          <SaveAsIcon />
        </IconButton>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: "green[500]",
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default EarlyEnlistmentCutOffDateActionButton;
