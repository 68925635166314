import { Box, CircularProgress, IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import React, { useEffect, useState } from "react";
import apiClientService from "../../services/apiClient.service";

const ApiClientActionButton = ({
  params,
  rowId,
  setRowId,
  cellEdited,
  setCellEdited,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function updateApiClient(row) {
    setCellEdited(null);
    setLoading(true);
    const data = {
      clientId: row.clientId,
      clientKey: row.clientKey,
      clientSecret: row.clientSecretPlain,
      remarks: row.remarks,
      status: row.status,
    };

    await apiClientService
      .updateApiClient(data)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {});
    setLoading(false);
    setRowId(null);
  }

  return (
    <Box key={params.id} sx={{ position: "relative" }}>
      {success && !cellEdited ? (
        <IconButton
          color="primary"
          sx={{
            width: 40,
            height: 40,
            backgroundColor: "green[500]",
            "&:hover": { backgroundColor: "green[700]" },
          }}
          onClick={() => setSuccess(false)}
        >
          <CheckCircleOutlineIcon style={{ color: "#07ab03" }} />
        </IconButton>
      ) : (
        <IconButton
          color="primary"
          disabled={params.id !== rowId || loading}
          onClick={() => updateApiClient(params.row)}
        >
          <SaveAsIcon />
        </IconButton>
      )}
      {loading && (
        <CircularProgress
          size={30}
          sx={{
            color: "green[500]",
            position: "absolute",
            top: 2,
            left: 2,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default ApiClientActionButton;
