import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import registerService from "../../services/register.service";
import { useTranslation, i18next } from "react-i18next";

const SignUpInstruction = (props) => {
  const { t, i18n } = useTranslation();
  const [ageCriteria, setAgeCriteria] = useState([]);
  const nonBreakingSpace = String.fromCharCode(160);

  useEffect(() => {
    props.setDisableNextBtn(false);
    getAgeCriteria();
  }, []);

  const getAgeCriteria = () => {
    registerService.getAgeCriteria().then(
      (response) => {
        setAgeCriteria(response.data);
      },
      (error) => {}
    );
  };
  return (
    <Box>
      <Box
        // sx={{ border: "1px solid blue" }}
        display={"flex"}
        flexDirection={"column"}
        padding={1}
      >
        <Typography align="justify">
          {/* To sign up for the Gyalsung portal, you must attain a minimum age of{" "}
          {ageCriteria.minimumAge} years and a maximum age of{" "}
          {ageCriteria.maximumAge} years. Please proceed to Next if you fall
          under this age group and wish to sign up for National Service. */}
          {t(
            "To sign up for the Gyalsung portal, you must attain a minimum age of {{minimumAge}} years and a maximum age of {{maximumAge}} years. Please proceed to Next if you fall under this age group and wish to sign up for National Service.",
            {
              minimumAge: ageCriteria.minimumAge,
              maximumAge: ageCriteria.maximumAge,
            }
          )}
        </Typography>
      </Box>

      <Box
        sx={{ border: "1px solid #c5c5c5" }}
        display={"flex"}
        flexDirection={"column"}
        padding={1}
        marginBottom={2}
        // alignItems={"center"}
      >
        <Typography align="justify" variant="h6">
          {t(
            "Please note that the following information is required to complete the sign-up"
          )}
          :
        </Typography>
        <Typography align="justify">
          <TaskAltIcon style={{ color: "green", fontSize: 16 }}> </TaskAltIcon>
          {nonBreakingSpace}
          {t("Correct CID number.")}
        </Typography>
        <Typography align="justify">
          <TaskAltIcon style={{ color: "green", fontSize: 16 }}> </TaskAltIcon>
          {nonBreakingSpace}
          {t("Correct DOB.")}
        </Typography>

        <Typography align="justify">
          <TaskAltIcon style={{ color: "green", fontSize: 16 }}> </TaskAltIcon>
          {nonBreakingSpace}
          {t(
            "Your correct mobile number (OTP verification will be sent to your mobile number.)"
          )}
        </Typography>
        <Typography align="justify">
          <TaskAltIcon style={{ color: "green", fontSize: 16 }}> </TaskAltIcon>
          {nonBreakingSpace}
          {t(
            "Your valid email address (A verification code will be sent to your email inbox. If you don't have one, please create one.)"
          )}
        </Typography>
        <Typography align="justify">
          <TaskAltIcon style={{ color: "green", fontSize: 16 }}> </TaskAltIcon>
          {nonBreakingSpace}
          {t(
            "You will need to enter a password with a minimum length of 8 characters."
          )}
        </Typography>
      </Box>
      <Typography align="justify">
        {t(
          "You will take approximately 3 to 5 minutes to complete signing up for the system. If you have already signed up, you may login to the system."
        )}
      </Typography>
    </Box>
  );
};

export default SignUpInstruction;
