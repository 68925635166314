import {CLEAR_MESSAGE, SET_MESSAGE} from "../actions/types";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {};

export const messageSlice = createSlice({
    name: "Message",
    initialState: initialState,
    reducers: {
        messenger: (state, action) => {
            const {type, payload} = action;
            switch (type) {
                case SET_MESSAGE:
                    return {message: payload};

                case CLEAR_MESSAGE:
                    return {message: ""};

                default:
                    return state;
            }
        }
    }
});

export default messageSlice.reducer;
export const {messenger} = messageSlice.actions;
