import { createSlice } from "@reduxjs/toolkit";

export const topBarTitleSetterSlice = createSlice({
  name: "topBarTitleChanger",
  initialState: {
    title: "Dashboard",
  },
  reducers: {
    changeTopBarTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

export default topBarTitleSetterSlice.reducer;
export const { changeTopBarTitle } = topBarTitleSetterSlice.actions;
