import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Backdrop,
  Box,
  Divider,
  Dialog,
  Snackbar,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  CircularProgress,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import PanToolIcon from "@mui/icons-material/PanTool";
import CustomTooltip from "../SignUp/CustomTooltip";
import moment from "moment";
import Alert from "@mui/lab/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import registerService from "../../services/register.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import OtpInput from "react-otp-input";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import useMediaQuery from "@mui/material/useMediaQuery";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";
import WebsiteHeader from "../Home/WebsiteHeader";
import guardianConsentServices from "../../services/guardianConsent.services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const GuardianConsent = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [guardianInfo, setGuardianInfo] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [guardianVerified, setGuardianVerified] = useState(false);
  const [showCidCircularProgress, setShowCidCircularProgress] = useState(false);

  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const otpTime = process.env.REACT_APP_OTP_TIME_OUT;
  const [otpTimeout, setOtpTimeout] = useState(otpTime);
  const [otp, setOtp] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [showMobileNoCircularProgress, setShowMobileNoCircularProgress] =
    useState(false);
  const [mobileNoVerified, setMobileNoVerified] = useState(false);
  const [approveSuccesss, setApproveSuccesss] = useState(false);
  const [deniedSuccess, setDeniedSuccess] = useState(false);

  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showDenyDialog, setShowDenyDialog] = useState(false);
  const [progress, setProgress] = useState(false);
  const [showTermsCondition, setShowTermsCondition] = useState(false);
  const [tocAccepted, setTocAccepted] = useState(false);
  const [enlistmentYear, setEnlistmentYear] = useState("2025");//todo need to get dynamic year

  const { values, setFieldValue, handleSubmit, resetForm, errors, touched } =
    useFormik({
      initialValues: {
        cid: "",
        dob: null,
      },
      onSubmit: () => {
        validateGuardian();
      },
      validationSchema: Yup.object().shape({
        cid: Yup.number().required("This field is required"),
        dob: Yup.date("Invalid date")
          .required("This field is required")
          .nullable(),
      }),
    });

  useEffect(() => {
    // width change
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    validateGuardianConsentLink();
  }, []);

  useEffect(() => {
    if (otpTimeout > 0) {
      const interval = setInterval(() => {
        setOtpTimeout((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpTimeout]);

  useEffect(() => {
    verifyOtp();
  }, [otp]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const validateGuardianConsentLink = () => {
    const uniqueId = params.get("id");
    let data = { uniqueId };
    guardianConsentServices.validateGuardianConsentLink(data).then(
      (response) => {
        setGuardianInfo(response.data);
      },
      (error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    );
  };

  const validateGuardian = async () => {
    setShowCidCircularProgress(true);
    setShowAlert(false);
    setErrorMsg("");
    const uniqueId = params.get("id");
    const guardianCid = values.cid;
    let data = { uniqueId, guardianCid };
    await guardianConsentServices.validateGuardian(data).then(
      (response) => {
        validateCitizenDetails();
      },
      (error) => {
        setShowAlert(true);
        setShowCidCircularProgress(false);
        setErrorMsg(error.response.data.message);
      }
    );
  };

  const validateCitizenDetails = async () => {
    const cid = values.cid;
    const dob = moment(values.dob).format("DD/MM/YYYY");
    registerService.validateCitizenDetails(cid, dob).then(
      (response) => {
        setGuardianVerified(true);
        setShowCidCircularProgress(false);
      },
      (error) => {
        setShowCidCircularProgress(false);
        setShowAlert(true);
        setErrorMsg(error.response.data.message);
      }
    );
  };

  const receiveOtp = async () => {
    setOtpTimeout(otpTime);
    setShowMobileNoCircularProgress(true);
    const data = { mobileNo, guardianCid: values.cid };
    await guardianConsentServices.receiveOtp(data).then(
      (response) => {
        setSnackbarMessage(`OTP sent to ${mobileNo}`);
        setShowSnackbar(true);
        setShowOtpDialog(true);
        setOtpTimeout(otpTime);
        setShowMobileNoCircularProgress(false);
      },
      (error) => {
        setSnackbarMessage(error.response.data.message);
        setShowSnackbar(true);
        setShowMobileNoCircularProgress(false);
      }
    );
  };

  const handlePhoneChange = (value, country) => {
    setMobileNo(value);
    setCountryCode(country.dialCode);
  };

  const verifyOtp = () => {
    const data = { guardianCid: values.cid, mobileNo, otp };
    if (otp.length === 6) {
      guardianConsentServices.verifyOtp(data).then(
        (response) => {
          setShowOtpDialog(false);
          setMobileNoVerified(true);
          setSnackbarMessage("Your mobile number has been verified.");
          setShowSnackbar(true);
        },
        (error) => {
          setSnackbarMessage(error.response.data.message);
          setShowSnackbar(true);
        }
      );
    }
  };

  const grantGuardianConsent = () => {
    setProgress(true);

    const uniqueId = params.get("id");
    const guardianCid = values.cid;

    const data = { uniqueId, guardianCid,enlistmentYear };

    guardianConsentServices.grantGuardianConsent(data).then(
      (response) => {
        setApproveSuccesss(true);
        setProgress(false);
        setShowApproveDialog(false);
      },
      (error) => {
        setProgress(false);
      }
    );
  };

  const denyGuardianConsent = () => {
    setProgress(true);

    const uniqueId = params.get("id");
    const guardianCid = values.cid;

    const data = { uniqueId, guardianCid };

    guardianConsentServices.denyGuardianConsent(data).then(
      (response) => {
        setDeniedSuccess(true);
        setProgress(false);
        setShowDenyDialog(false);
      },
      (error) => {
        setProgress(false);
      }
    );
  };

  const handleApproveDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setShowApproveDialog(false);
  };
  const handleDenyDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setShowDenyDialog(false);
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box bgcolor="white" position={"relative"}>
      {/*top bar*/}
      <WebsiteHeader initialY={"-10vh"} animateY={"0vh"} />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        action={snackbarAction}
      />
      <Box
        display="flex"
        justifyContent="center"
        height="80vh"
        alignItems="center"
        position={"relative"}
      >
        <Box
          borderRadius={2}
          boxShadow={3}
          display="flex"
          flexDirection="column"
          width={{ md: "60%", sx: "100%" }}
          maxHeight="inherit"
          sx={{
            background: "rgba(255, 255, 255, 0.77)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            "*::WebkitBackdropFilter": "blur(5px)",
            border: "1px solid rgba(255, 255, 255, 1)",
          }}
        >
          <Box
            p={{ xs: 5, sm: 5 }}
            display="flex"
            flexDirection="column"
            gap={2}
          >
            {approveSuccesss || deniedSuccess ? (
              <>
                {approveSuccesss ? (
                  <>
                    <Alert icon={false} severity="success">
                      <Typography
                        className={"text-center"}
                        variant="h4"
                        gutterBottom
                      >
                        <TaskAltIcon style={{ fontSize: 30 }} /> Guardian
                        Consent Supported
                      </Typography>
                      <Typography>
                        You have supported your ward's application for early
                        enlistment.
                      </Typography>

                      <Typography>Thank you.</Typography>
                    </Alert>
                  </>
                ) : (
                  <>
                    <Alert icon={false} severity="warning">
                      <Typography
                        className={"text-center"}
                        variant="h4"
                        gutterBottom
                      >
                        <PanToolIcon style={{ fontSize: 30 }} /> Guardian
                        Consent Not Supported
                      </Typography>
                      <Typography>
                        You have not supported your ward's application for early
                        enlistment.
                      </Typography>

                      <Typography>Thank you.</Typography>
                    </Alert>
                  </>
                )}
              </>
            ) : (
              <>
                {guardianInfo.status === "P" ? (
                  <>
                    <Stack
                      spacing={2}
                      justifyContent="center"
                      alignItems="Item"
                    >
                      <Typography align="center" variant="h5">
                        Parent/Guardian Consent Request
                      </Typography>

                      <Typography align="center" variant="h6">
                        Dear {guardianInfo.guardianName},
                      </Typography>
                      <Typography align="center" variant="h6">
                        You are the legal guardian of {guardianInfo.fullName}.
                      </Typography>
                      <Typography align="center" variant="h6">
                        Please login to indicate your consent or non-consent for
                        your ward's early enlistment application.
                      </Typography>
                    </Stack>
                    <Stack
                      justifyContent="center"
                      direction={{ md: "row", xs: "column" }}
                      spacing={1}
                      gap={1}
                    >
                      <TextField
                        disabled={guardianVerified || showCidCircularProgress}
                        value={values.cid}
                        placeholder="CID"
                        label="CID"
                        size="small"
                        type="number"
                        required
                        inputProps={{ autoComplete: "off" }}
                        onChange={(e) => {
                          setFieldValue("cid", e.target.value);
                        }}
                        error={touched.cid && errors.cid}
                        helperText={touched.cid && errors.cid}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disabled={guardianVerified || showCidCircularProgress}
                          inputProps={{ autoComplete: "off" }}
                          views={["year", "month", "day"]}
                          label="Date of Birth*"
                          inputFormat="DD/MM/YYYY"
                          maxDate={new Date()}
                          value={values.dob}
                          onChange={(selectedDate) => {
                            try {
                              setFieldValue("dob", new Date(selectedDate));
                            } catch (err) {}
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              error={touched.dob && errors.dob}
                              helperText={touched.dob && errors.dob}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Box>
                        {!guardianVerified ? (
                          <>
                            <Button
                              disabled={
                                guardianVerified || showCidCircularProgress
                              }
                              variant="contained"
                              onClick={() => handleSubmit()}
                              type="submit"
                              color="info"
                              fullWidth
                              style={{ textTransform: "initial" }}
                            >
                              <Typography noWrap>
                                <SearchIcon /> Check guardian
                              </Typography>
                              {showCidCircularProgress && (
                                <CircularProgress size="1rem" />
                              )}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Typography noWrap>
                              <TaskAltIcon
                                style={{ fontSize: 23 }}
                                color="success"
                              />
                              Verified
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Stack>
                    <Stack
                      justifyContent="center"
                      direction={{ md: "row", xs: "column" }}
                      spacing={1}
                      gap={1}
                    >
                      {guardianVerified ? (
                        <>
                          <Box
                            display="flex"
                            justifyContent="center"
                            gap={2}
                            flexDirection={{ xs: "column", sm: "row" }}
                          >
                            <Box flex={2}>
                              <div className="phone-input-container">
                                <PhoneInput
                                  inputStyle={{ width: "100%" }}
                                  enableSearch
                                  country={"bt"}
                                  value={mobileNo}
                                  onChange={handlePhoneChange}
                                  disabled={
                                    showMobileNoCircularProgress ||
                                    mobileNoVerified
                                  }
                                />
                                <label
                                  htmlFor="phone-input"
                                  className={`floating-label ${
                                    mobileNo ? "filled" : ""
                                  }`}
                                >
                                  Mobile Number*
                                </label>
                              </div>
                              <CustomTooltip text="Mobile number to receive OTP">
                                <Typography variant="body2">
                                  <InfoIcon color="info" />
                                </Typography>
                              </CustomTooltip>
                            </Box>
                            {countryCode === "975" ? (
                              <Box flex={1}>
                                {!mobileNoVerified ? (
                                  <Button
                                    disabled={
                                      mobileNoVerified ||
                                      showMobileNoCircularProgress
                                    }
                                    variant="contained"
                                    onClick={receiveOtp}
                                    type="submit"
                                    color="info"
                                    fullWidth
                                    style={{ textTransform: "initial" }}
                                  >
                                    <Typography noWrap>
                                      Send OTP <SendIcon />
                                    </Typography>
                                    {showMobileNoCircularProgress && (
                                      <CircularProgress size="1rem" />
                                    )}
                                  </Button>
                                ) : (
                                  <Typography noWrap>
                                    <TaskAltIcon
                                      style={{ fontSize: 23 }}
                                      color="success"
                                    />
                                    Verified
                                  </Typography>
                                )}
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>

                    <Stack
                      justifyContent="center"
                      direction={{ md: "row", xs: "column" }}
                      spacing={1}
                      gap={1}
                    >
                      {guardianVerified ? (
                        <Box display="flex" flexDirection="row" gap={2}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox checked={tocAccepted} disabled={true} />
                            <Button
                              disableRipple
                              disableFocusRipple
                              onClick={(e) => setShowTermsCondition(true)}
                            >
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  color: "blue",
                                }}
                              >
                                Terms and Conditions
                              </Typography>
                            </Button>
                          </div>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    {mobileNoVerified ||
                    (countryCode !== "975" && countryCode !== "") ? (
                      <>
                        <Stack
                          justifyContent="center"
                          direction={{ md: "row", xs: "column" }}
                          spacing={1}
                          gap={1}
                        >
                          <Box>
                            <Button
                              disabled={!tocAccepted}
                              variant="contained"
                              type="submit"
                              color="warning"
                              fullWidth
                              style={{ textTransform: "initial" }}
                              onClick={() => setShowDenyDialog(true)}
                            >
                              <Typography>
                                <ThumbDownIcon /> Not Support
                              </Typography>
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              disabled={!tocAccepted}
                              variant="contained"
                              type="submit"
                              color="success"
                              fullWidth
                              style={{ textTransform: "initial" }}
                              onClick={() => setShowApproveDialog(true)}
                            >
                              <Typography>
                                <ThumbUpIcon /> Support
                              </Typography>
                            </Button>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <></>
                    )}
                    <Stack
                      justifyContent="center"
                      direction={{ md: "row", xs: "column" }}
                      spacing={1}
                      gap={1}
                    >
                      <Collapse in={showAlert}>
                        <Alert
                          severity="warning"
                          action={
                            <IconButton onClick={() => setShowAlert(false)}>
                              <CloseIcon />
                            </IconButton>
                          }
                        >
                          {errorMsg}
                        </Alert>
                      </Collapse>
                    </Stack>
                  </>
                ) : (
                  <>
                    {guardianInfo.status === "A" ||
                    guardianInfo.status === "R" ? (
                      <>
                        <Typography>
                          <Alert severity="warning">
                            You have already used this link.
                          </Alert>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography>
                          <Alert severity="info">
                            {/* Invalid URL.  */}
                            <Typography>
                              You just clicked an expired or invalid link.
                              Please ask your son or daughter to request your
                              consent once more, and you will receive a new link
                              in an SMS and an email.
                            </Typography>
                            <Typography>
                              Thank you for your patience.
                            </Typography>
                          </Alert>
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>

      {/*otp dialog*/}
      <Dialog
        open={showOtpDialog}
        PaperProps={{ style: { backgroundColor: "#f3f3f3" } }}
      >
        <Box display={"block"}>
          <DialogTitle>
            <Typography textAlign="center">
              Please enter OTP in{" "}
              <span style={{ color: "red" }}>{formatTime(otpTimeout)}</span>{" "}
              (MM:SS)
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {otpTimeout > 0 ? (
                <OtpInput
                  value={otp}
                  onChange={(code) => {
                    setOtp(code);
                  }}
                  numInputs={6}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: isMobileView ? screenWidth * 0.1 : "54px",
                    height: isMobileView ? screenWidth * 0.1 : "54px",
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "400",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                  shouldAutoFocus={true}
                />
              ) : (
                <>
                  <Alert severity="warning">
                    <Typography>
                      Time lasped. Please request a new OTP. Click Resend if the
                      mobile number is the same.
                    </Typography>
                  </Alert>
                </>
              )}

              <Typography textAlign="center">
                OTP code is sent to +{mobileNo} Didn't receive?{" "}
                <Button
                  variant="text"
                  style={{ textTransform: "initial" }}
                  // disabled={showSendOtpProgress}
                  onClick={receiveOtp}
                >
                  <Typography style={{ color: "#0088d2" }}>Resend</Typography>
                </Button>
              </Typography>
            </Box>
          </DialogContent>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "initial" }}
            variant="contained"
            color="warning"
            onClick={() => {
              setShowOtpDialog(false);
              setShowMobileNoCircularProgress(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* approve dialog */}
      <Dialog
        TransitionComponent={Transition}
        fullWidth={true}
        open={showApproveDialog}
        onClose={handleApproveDialogClose}
        aria-labelledby="alert-dialog-title"
        sx={{ borderTop: "5px solid red" }} // Use sx prop for custom styles
      >
        <DialogContent sx={{ borderTop: "6px solid #2f7d31" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={1}>
              <Typography align="center" variant="h3">
                <HelpIcon sx={{ fontSize: "30px", color: "#ed6d03" }} />{" "}
                Confirmation
              </Typography>
              <Typography align="center" variant="h6">
                You are about to support {guardianInfo.fullName} for early
                enlistment. Are you sure?
              </Typography>
              <Typography align="center" variant="body2">
                It cannot be undone once you confirm.
              </Typography>
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "initial" }}
            variant="contained"
            color="warning"
            onClick={() => {
              setShowApproveDialog(false);
            }}
          >
            No, Cancel
          </Button>

          <Button
            style={{ textTransform: "initial" }}
            disabled={progress}
            variant="contained"
            color="success"
            onClick={grantGuardianConsent}
          >
            Yes, Sure
            {progress && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  p: 1,
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reject dialog */}
      <Dialog
        TransitionComponent={Transition}
        fullWidth={true}
        open={showDenyDialog}
        onClose={handleDenyDialogClose}
        aria-labelledby="alert-dialog-title"
        sx={{ borderTop: "5px solid red" }} // Use sx prop for custom styles
      >
        <DialogContent sx={{ borderTop: "6px solid red" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={1}>
              <Typography align="center" variant="h3">
                <HelpIcon sx={{ fontSize: "30px", color: "#ed6d03" }} />{" "}
                Confirmation
              </Typography>
              <Typography align="center" variant="h6">
                You are about to not support {guardianInfo.fullName} for early
                enlistment. Are you sure?
              </Typography>
              <Typography align="center" variant="body2">
                It cannot be undone once you confirm.
              </Typography>
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            style={{ textTransform: "initial" }}
            variant="contained"
            color="warning"
            onClick={() => {
              setShowDenyDialog(false);
            }}
          >
            No, Cancel
          </Button>

          <Button
            style={{ textTransform: "initial" }}
            disabled={progress}
            variant="contained"
            color="success"
            onClick={denyGuardianConsent}
          >
            Yes, Sure
            {progress && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  p: 1,
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Terms and Conditions Dialog*/}
      <Dialog
        open={showTermsCondition}
        onClose={(e) => setShowTermsCondition(false)}
        fullWidth
      >
        <DialogTitle>
          <Typography align="center" variant="h5">
            <strong>TERMS AND CONDITIONS FOR VOLUNTARY EARLY ENLISTMENT</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: "justify" }}>
            1. Under the Gyalsung Act of the Kingdom of Bhutan, 2022, a
            Bhutanese who attains the age of eighteen years shall be required to
            enlist for Gyalsung Training. However, notwithstanding this general
            age criterion, Section 14 of the Act permits a person below the age
            of eighteen years but above the age of sixteen years and six months
            to volunteer for early registration
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            2. The voluntary Early Enlistment provision for Gyalsung Training
            shall, unless the Gyalsung Headquarters specifies otherwise through
            amendment of the existing laws, be provided to only such persons who
            are below the age of eighteen years but above the age of sixteen
            years and six months on 1st January of the year of intended early
            enlistment and who have completed Class Twelve or equivalent.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            3. The Gyalsung Headquarters shall accept an Application Form for
            Voluntary Early Enlistment only when accompanied by a duly filled
            Consent Form of the applicant's genuine parent or guardian.
            Providing such consent through the prescribed form entails that the
            parent or guardian accepts to subject his or her child or ward to
            Gyalsung's Act, Rules and Regulations, Code of Conduct and Orders or
            Instructions that may be passed by the authorities from time to
            time.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            4. Persons who volunteer to enlist early will be put through a
            medical and mental fitness examination and an aptitude assessment to
            ascertain whether they can cope with training. Each application will
            be decided on a case-by-case basis by the Headquarters.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            5. The Gyalsung Headquarters reserves the power to enlist a person
            applying for early enlistment into Gyalsung Training and in doing so
            it may consider factors such as the training capacity after taking
            in eligible persons who have attained 18 years of age.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            6. The persons volunteering for early enlistment shall be required
            to register through Online Early Enlistment Portal provided by the
            Gyalsung Management Information System (GMIS).
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            7. The persons enlisted through the Early Enlistment provision will
            undergo the same training and be accorded equal treatment in all
            matters concerning Gyalsung Program, including Gyalsung Duty, as
            other persons who are enlisted when or after they reach 18 years of
            age.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            8. The persons enlisted through the Early Enlistment provision will
            be subject to laws and acts, including orders and directives issued
            by the Gyalsung Headquarters. Their eligibility for allowance,
            annual leave, medical benefits and other benefits, if any, will be
            the same as those accorded to those enlisted when or after they
            reach 18 years of age.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            9. Persons who would like to apply for Voluntary Early Enlistment
            should complete the following application form.
          </Typography>
          <br />
          <Typography style={{ textAlign: "justify" }}>
            10. The Gyalsung Headquarters reserves the power to revoke the
            enlistment of a person whose information declared hereunder is found
            to be untrue.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            startIcon={<ThumbDownIcon />}
            style={{ textTransform: "initial" }}
            // disabled={progress}
            variant="contained"
            color="error"
            onClick={() => {
              setTocAccepted(false);
              setShowTermsCondition(false);
            }}
          >
            Close
            {/* {progress && (
              <CircularProgress
                size={30}
                sx={{
                  position: "absolute",
                  p: 1,
                }}
              /> */}
            {/* )} */}
          </Button>

          <Button
            startIcon={<ThumbUpIcon />}
            style={{ textTransform: "initial" }}
            disabled={progress}
            variant="contained"
            color="success"
            onClick={(e) => {
              setTocAccepted(true);
              setShowTermsCondition(false);
            }}
          >
            Accept
            {/* {progress && (
              <CircularProgress
                size={30}
                sx={{
                  position: "absolute",
                  p: 1,
                }}
              />
            )} */}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GuardianConsent;
